import { useState,useEffect } from 'react';
function SmallPromoBox({url,cta,color}) {
    const [isVisible,setIsVisible]=useState(false);
    useEffect(() => {
	if(url && cta)setIsVisible(true);
	else setIsVisible(false);
    },[url,cta]);

    const textcolor={color:color};
    const bgcolor={backgroundColor:color};
    const bordercolor={borderColor:color};

    return ( <>{ isVisible ? (
        <>
            <div className='col-6'>
                <div className='dash-box internal-box mb-3 ms-2 me-2' style={bordercolor}>
                    <div className="w-100 d-flex justify-content-end">
                        <a href={url} target='_blank' rel="noreferrer" className="open-window-smallbox" style={bgcolor} ></a>
                    </div>
                    <div>
                        <h4 style={textcolor}>{cta}</h4>
                    </div>
                </div>
            </div>
        </>
    ) : ( <></> ) } </>)
}

export default SmallPromoBox;
