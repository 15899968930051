import {useState,useEffect} from 'react';
//import {formatprice} from '../../us';

const CheckoutPaymentScalaPay=function({orderinfo,myhubcode,paymenthubs,paymenthub,setCurPaymentHub}){
	const [isenabled,setIsEnabled]=useState(null);

	useEffect(()=>{
		const cb=function(){
			/*let found=false;
			for(let k in orderinfo.paymenthubs){
				for(let h in paymenthubs){
					if(paymenthubs[h].uuid==orderinfo.paymenthubs[k]){
						if(paymenthubs[h].code==myhubcode)found=true;
						break;
					}
				}
			}*/
			setIsEnabled(typeof(paymenthubs[myhubcode])!='undefined');
		};
		if(isenabled===null && orderinfo && paymenthubs)cb();
	},[isenabled,orderinfo,paymenthubs,myhubcode]);

	return (<>
			{isenabled?(<>

                                    <div className='row align-items-center pt-4'>
                                        <div className='col-auto'>
                                            <input name='paymenthub' className='d-flex' type="radio" value={myhubcode} onClick={setCurPaymentHub} checked={myhubcode===paymenthub} />
                                        </div>
                                        <div className='col-auto'>
                                            <h5 className='m-0'>Scalapay</h5>
                                        </div>
                                        <div className='col'><p className='small'>paga in 3 rate a zero interessi</p></div>
                                        <div className='col-auto'>
                                            <img src={`${process.env.PUBLIC_URL}/scalapay.svg`} alt="Scalapay" />
                                        </div>
                                    </div>
                                    <div className='divider-row'></div>
				<>{myhubcode===paymenthub?(<>
                                    <div className='row pt-2 align-items-center'>
                                        <div className='col'><p className='small'>Paga in <span>3</span> comode rate da <span>€ 14,34 senza interessi</span>.<br />
                                        Ricevi subito il tuo ordine. Goditi il tuo acquisto e prenditi il tempo di pgarlo lentamente.</p></div>                        
                                    </div>
                                    <div className='divider-row'></div>
                                    <div className='row align-items-center pt-4'>
                                        <div className='col-auto'>
                                            <input name='paymenttype' className='d-flex' type="radio" />
                                        </div>
                                        <div className='col'>
                                            <h5 className='m-0'>Carta dei servizi</h5>
                                        </div>
                                        <div className='col-auto'>
                                            <img src={`${process.env.PUBLIC_URL}/creditcard.svg`} alt="Carta servizi" />
                                        </div>
                                    </div>
                                    <div className='divider-row'></div>
				</>):(<></>)}</>
			</>):(<></>)}
</>);
};
export default CheckoutPaymentScalaPay;
