import { Link } from 'react-router-dom';

function PrevPage() {

    return (

        <div className="col-1 prev-page">
        <Link to='/dashboard/'>
            <img src={`${process.env.PUBLIC_URL}/page-back.svg`} alt="Torna indietro" />
            <span>MyHome</span>
        </Link>
        </div>

    )
};

export default PrevPage;