import SelectedContentBox from './selectedContent';
import CardCorso from './CardCorso';
import { useEffect, useState,useContext/*,useRef*/ } from 'react';
import { useAuth } from 'react-oidc-context';
import AppContext from '../../../AppContext';
import {uscall/*,getfirst*/} from '../../../us';
//import UserContext from '../../../UserContext';
import CommonContext from '../../../CommonContext';

export default function CorsiForYou(){
    const auth = useAuth();
    const appcontext = useContext(AppContext);
    //const usercontext = useContext(UserContext);
        const commoncontext = useContext(CommonContext);

	const [producttype_corsi,setProductTypeCorsi]=useState(null);
    const [products,setProducts]=useState(null);
	//const now=(new Date()).valueOf();
    useEffect(() => {
	const cb=async function(){
		let bs=[];
		for(let i in commoncontext.producttypes){
			if(['corso'].includes(commoncontext.producttypes[i].code))bs.push(commoncontext.producttypes[i].uuid);
		}
		setProductTypeCorsi(bs);
	}
	if((!producttype_corsi) && commoncontext && commoncontext.producttypes)
		cb();
    },[auth,appcontext,commoncontext,producttype_corsi]);

    useEffect(() => {
	const cb=async function(){
		//let filter={func:'and',fields:[{field:'type',func:'in',value:producttype_corsi},{field:'available',func:'is',value:true}/*,{field:'pubdate',func:'gte',value:now}*/]};
		//const ps=await uscall(appcontext,auth,'ecommerce',null,'product_search',null,{filter:filter,limit:20,sort:{field:'pubdate',desc:true}});
		const ps=await uscall(appcontext,auth,'ecommerce',null,'magento_getcorsi',null,{limit:20});//TODO: filtro solo libri per studenti
		if(typeof(ps['error'])!='undefined')return;
		setProducts(ps.result);
	}
	if(products);else if(producttype_corsi) cb();
    },[auth,appcontext,/*usercontext,*/products,producttype_corsi]);

					//<CardCorso title={product.name} authors={product.shortauthors} type='TODOAula + Streaming' description='TODO29 Lezioni, 24 Esercitazioni' url={product.url} />
	return (<>
			{products ?(
				<>{products.length>0?(
		<SelectedContentBox title='Corsi per te' allurl={process.env.REACT_APP_PREFIX_MOODLECORSI}>
				{products.map(product=>(
					<CardCorso key={product.uuid?product.uuid:product.magento_product_id} title={product.name} authors={product.shortauthors} type='Aula + Streaming' description='29 Lezioni, 24 Esercitazioni' url={product.url} imageurl={product?.magento_custom?.image} />
				))}
		</SelectedContentBox>
				):(
					<></>
				)}</>
			):(
		<SelectedContentBox title='Corsi per te' allurl={process.env.REACT_APP_PREFIX_MOODLECORSI}>
					<CardCorso key='fake1' />
					<CardCorso key='fake2' />
					<CardCorso key='fake3' />
					<CardCorso key='fake4' />
					<CardCorso key='fake5' />
		</SelectedContentBox>
			)}
	</>);
};

