import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { CustomButtonGroup } from '../../utilities/customArrowsCarousel';
/*import { Children } from 'react';
				{Children.map(children, child =>
				        <div>
				          {child}
				        </div>
			      )}*/

const SelectedContentBox = ({ children,title,allurl}) => {
    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 5,
            slidesToSlide: 5
        },
        tablet: {
            breakpoint: { max: 1024, min: 768 },
            items: 2,
            slidesToSlide: 2
        },
        mobile: {
            breakpoint: { max: 768, min: 0 },
            items: 1,
            slidesToSlide: 1
        }
    };

    return (
	<>
                <div className='dash-box selected-contents'>
                    <div className="row align-items-center w-100">
                        <div className='col-auto'>
                            <h1 className="m-0 p-0">{title}</h1>
                        </div>
                        <div className="col">
                           {/* <button className="yellow-button selected">
                                <span>Tutti (12)</span>
                            </button>
                            <button className="yellow-button">
                                <span>Libri (12)</span>
                            </button>
                            <button className="yellow-button">
                                <span>Corsi (12)</span>
                            </button>
                            <button className="yellow-button">
                                <span>Riviste (12)</span>
                            </button>*/}
                        </div>
                        <div className='col-auto p-0 m-0'>
                            <a href={allurl} className="yellow-button goto m-0"><span>Vedi tutti</span></a>
                        </div>
                    </div>
                    <div className='custom-carousel-container big-carousel w-100 h-100'>
			{children && children.length>0 && (
                        <Carousel
                            arrows={false}
                            renderButtonGroupOutside={true}
                            customButtonGroup={<CustomButtonGroup />}
                            swipeable={true}
                            draggable={true}
                            showDots={false}
                            responsive={responsive}
                            ssr={true} // means to render carousel on server-side.
                            infinite={true}
                            autoPlay={false}
                            autoPlaySpeed={1000}
                            keyBoardControl={true}
                            customTransition="transform 0.5s ease-in-out"
                            transitionDuration={500}
                            containerClass="carousel-container"
                            removeArrowOnDeviceType={["tablet", "mobile"]}
                            dotListClass="custom-dot-list-style"
                        >
				{children}
                        </Carousel>)}
                    </div>
                </div>
	</>
    )
}

export default SelectedContentBox;
