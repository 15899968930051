import {useState,useEffect} from 'react';
//import {formatprice} from '../../us';
import {Elements,useElements,PaymentElement} from '@stripe/react-stripe-js';

const CheckoutPaymentStripeE=function({stripeElementsO,setStripeElementsO}){
	const elements = useElements();

	useEffect(()=>{
		if(elements && (!stripeElementsO))
			setStripeElementsO(elements);
	},[stripeElementsO,elements,setStripeElementsO]);

	return (<></>);
};

const CheckoutPaymentStripe=function({orderinfo,myhubcode,paymenthubs,paymenthub,setCurPaymentHub,stripePromises,stripeOptions,stripeElementsO,setStripeElementsO}){
	const [isenabled,setIsEnabled]=useState(null);

	useEffect(()=>{
		const cb=function(){
			/*let found=false;
			for(let k in orderinfo.paymenthubs){
				for(let h in paymenthubs){
					if(paymenthubs[h].uuid==orderinfo.paymenthubs[k]){
						if(paymenthubs[h].code==myhubcode)found=true;
						break;
					}
				}
			}*/
			setIsEnabled(typeof(paymenthubs[myhubcode])!='undefined');
		};
		if(isenabled===null && orderinfo && paymenthubs)cb();
	},[isenabled,orderinfo,paymenthubs,myhubcode]);

	return (<>
			{isenabled?(<>
                                <div className='form-checkout'>
                                    <div className='row align-items-center pt-4'>
                                        <div className='col-auto'>
                                            <input name='paymenthub' className='d-flex' type="radio" value={myhubcode} onClick={setCurPaymentHub} checked={myhubcode===paymenthub} />
                                        </div>
                                        <div className='col'>
                                            <h5 className='m-0'>Carta di credito o debito</h5>
                                        </div>
                                        <div className='col-auto'>
                                            <img src={`${process.env.PUBLIC_URL}/creditcard.svg`} alt="Carta di credito o debito" />
                                        </div>
                                    </div>
                                    <div className='divider-row'></div>
                                </div>
				<>{myhubcode===paymenthub?(<>
                                <div className='form-checkout'>
					{stripePromises && stripeOptions?(
				    <Elements stripe={stripePromises[paymenthub]} options={stripeOptions}>
						<CheckoutPaymentStripeE stripeElementsO={stripeElementsO} setStripeElementsO={setStripeElementsO} />
						<PaymentElement />
				    </Elements>):(<></>)}
                                </div>
                                    <div className='divider-row'></div>
				</>):(<></>)}</>
			</>):(<></>)}
</>);
};
export default CheckoutPaymentStripe;
