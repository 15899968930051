import React from 'react';

const CustomToastContent = ({ iconPath, title, message, link }) => (
    <div className="custom-toast-content">
        {iconPath && <img src={iconPath} alt="icon" className="toast-icon" />}
        <div className="toast-text">
            <h2 className="toast-title">{title}</h2>
            <p className="toast-message">{message}</p>
            {link && <div className="toast-link">{link}</div>}
        </div>
    </div>
);

export default CustomToastContent;
