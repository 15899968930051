import "./profile.css";
import { useNavigate,Link } from 'react-router-dom';
import { useAuth } from 'react-oidc-context';
import { useEffect,useState,useContext } from 'react';
import { ToastContainer } from 'react-toastify';
import AppContext from '../../../AppContext';
import {uscall/*,getfirst*/} from '../../../us';
import { notifyWarn,notifySuccess } from '../../utilities/toaster';
import UserContext from '../../../UserContext';
import PrevPage from "../../utilities/prevPage";
//import CustomModal from '../../utilities/modals';

const ChangePassword = ({reloadusercontext}) => {
    const auth = useAuth();
    const appcontext = useContext(AppContext);
    const usercontext = useContext(UserContext);
    let navigate = useNavigate();
    useEffect(() => {
      if(auth.isAuthenticated);
      else 
        navigate('/login/?redirect='+encodeURIComponent(document.location.href));
    },[auth,navigate]);

    const [tokenInfo,setTokenInfo]=useState(null);
    //const [userInfo,setUserInfo]=useState(null);
    const [currentpassword, setCurrentPassword] = useState('');
    const [password, setPassword] = useState('');
    const [confirmpassword, setConfirmPassword] = useState('');
    const [isSubmitted, setIsSubmitted] = useState(false);// used to raise warnings
    const [isSubmitting, setIsSubmitting] = useState(false);// used to avoid double submissions

	const loadTokenInfo=async function(){
		//const tokeninfo=(await uscall(appcontext,auth,'aaa','session','tokeninfo',null,null));
		//if(tokeninfo);else return;
		const tokeninfo=usercontext.tokeninfo;
		setTokenInfo(tokeninfo);
		//setUserInfo(usercontext.user);
	}

    useEffect(() => {
	if((!tokenInfo) && usercontext && usercontext.user){
		loadTokenInfo();
	}
    }, [tokenInfo,auth,appcontext,usercontext/*,loadTokenInfo*/]);




    /*const [showModal, setShowModal] = useState(false);
    const [modalType, setModalType] = useState('resetPasswordInstructions');

    const handleCloseModal = () => setShowModal(false);
    const handleShowModal = (type) => {
        setModalType(type);
        setShowModal(true);
    };

    const handleSubmitModal1 = () => {
        handleCloseModal();
        handleShowModal('passwordResetConfirmation');
    };

    const modalContent = {
        resetPasswordInstructions: {
            title: 'Sostituzione password',
            bodyContent: 'Ciao Utente, non preoccuparti se non ricordi più la tua password, ti inviamo le istruzioni per aggiornarla.<br/>Invieremo le istruzioni alla mail:',
            bodySubtitle: userInfo.email,
        },
        passwordResetConfirmation: {
            title: 'Sostituzione password',
            bodyContent: 'Ciao Utente, abbiamo inviato le istruzioni alla mail:',
            bodySubtitle: userInfo.email,
        },
    };

    const handleCloseButtonClick = () => {
        handleCloseModal();
    };*/






    const checkPassword=function(v){
	return (v.trim().length>0 && v.trim().match(/^.*(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!#$%&@?-]).*$/))
	// https://stackoverflow.com/questions/2370015/regular-expression-for-password-validation
	// (?=.{8,})        : Length
	// (?=.*[a-zA-Z])   : Letters
	// (?=.*\d)         : Digits
	// (?=.*[!#$%&?"]) : Special characters
    };

    const doChangePassword=function(){
	return new Promise(async function(resolve,reject){
		let r=await uscall(appcontext,auth,'aaa','user','verifypassword',null,{uuid:tokenInfo.user,password:currentpassword});
		if(typeof(r['error'])!='undefined')return reject(r);
		if(typeof(r['result'])!='undefined' && r.result);else return reject();
		let r2=await uscall(appcontext,auth,'aaa','user','changepassword',null,{uuid:tokenInfo.user,password:password});
		if(typeof(r2['error'])!='undefined')return reject(r);
		resolve(r2);
	});
    }
    const handleSubmit = (e) => {
        e.preventDefault();
	if(isSubmitting)return;

	if(checkPassword(password));else return;
	if(checkPassword(confirmpassword));else return;
	if(password===confirmpassword);else return;
        setIsSubmitted(true);
        doChangePassword().then(function(){
        	setIsSubmitting(false);
        	setIsSubmitted(false);
		notifySuccess('Password aggiornata');
		reloadusercontext();
	},function(err){
        	setIsSubmitting(false);
        	setIsSubmitted(false);
            	notifyWarn(
            	    process.env.PUBLIC_URL+'alert.svg',
            	    'Si è verificato un errore',
            	    <>{err?.error?.description}</>,
            	);
	});
    };


    return (
        <div className="account-container">
            <div className="account-top">
                <div className="row h-100 align-items-center">
                    <PrevPage />
                    <div className="col-10 text-center">
                        <h1>Modifica Password</h1>
                    </div>
                    <div className="col-1"></div>
                </div>
            </div>
            <div className="account-body">
                <form onSubmit={handleSubmit}>
                    <h2>Cambio password</h2>
                    <div className='row'>
                        <div className='col-md-6'>
                            <label htmlFor="password" className='form-label'>Password attuale *</label>
                            <input
                                type='password'
                                id="password"
                                placeholder='Password'
                                value={currentpassword}
                                onChange={(e) => setCurrentPassword(e.target.value)}
                                required={isSubmitted}
                            />
                        </div>
                        <div className="col-md-6">
                            <h4>Password dimenticata</h4>
                            <p>Non preoccuparti, puoi resettare la<br />
                                password cliccando su <Link to="/forgot-password/">Resetta password</Link> {/*  onClick={() => handleShowModal('resetPasswordInstructions')} */}
                            </p>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-6'>
                            <label htmlFor="newpassword" className='form-label'>Nuova password *</label>
                            <input
                                type='password'
                                id="newpassword"
                                placeholder='Password'
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required={isSubmitted && !checkPassword(password)}
				pattern="^.*(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!#$%&@?\-]).*$"
                            />
                        </div>
                        <div className='col-md-6'>
                            <label htmlFor="confirmpassword" className='form-label'>Conferma password *</label>
                            <input
                                type='password'
                                id="confirmpassword"
                                placeholder='Password'
                                value={confirmpassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                required={isSubmitted && !checkPassword(confirmpassword)}
				pattern="^.*(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!#$%&@?\-]).*$"
                            />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-6'>
                            <h4>La password deve comprendere:</h4>
                            <ul>
                                <li>8 caratteri o più</li>
                                <li>almeno un carattere speciale (# ! ? -)</li>
                                <li>almeno 1 lettera maiuscola</li>
                                <li>almeno 1 numero</li>
                            </ul>
                        </div>
                    </div>
                    <div className="row buttons-container">
                        <div className="col-md-6">
                            <input className="gradient-button reset-button" type="reset" value="Annulla" />
                        </div>
                        <div className="col-md-6">
                            <input className="gradient-button" type="submit" value="Salva" />
                        </div>
                    </div>
                </form>
                    <ToastContainer className="custom-toast custom-toast-warning" />
            </div>

            {/* Modali */}
            {/*<CustomModal
                modalType={modalType}
                showModal={showModal}
                handleClose={handleCloseModal}
                handleSubmit={handleSubmitModal1}
                title={modalContent[modalType].title}
                bodyContent={modalContent[modalType].bodyContent}
                bodySubtitle={modalContent[modalType].bodySubtitle}
                closeButtonText="Chiudi"
                closeButtonAction={handleCloseButtonClick}
            />*/}
        </div>
    );
}

export default ChangePassword;
