import "./profile.css";
import { useNavigate,Link } from 'react-router-dom';
import { useState,useContext,useEffect/*,useRef*/ } from 'react';
import { useAuth } from 'react-oidc-context';
import AppContext from '../../../AppContext';
import {uscall/*,getfirst*/} from '../../../us';
import { notifyWarn,notifySuccess } from '../../utilities/toaster';
import { ToastContainer } from 'react-toastify';
import UserContext from '../../../UserContext';
import PrevPage from "../../utilities/prevPage";

const Preferences = ({reloadusercontext}) => {
    const auth = useAuth();
    const appcontext = useContext(AppContext);
    const usercontext = useContext(UserContext);
    let navigate = useNavigate();
    useEffect(() => {
      if(auth.isAuthenticated);
      else 
        navigate('/login/?redirect='+encodeURIComponent(document.location.href));
    },[auth,navigate]);
    const [userData,setUserData]=useState(null);
    const [customize,setCustomize]=useState(null);
    const [marketing,setMarketing]=useState(null);
	const loadUserData=async function(){
		//const tokeninfo=(await uscall(appcontext,auth,'aaa','session','tokeninfo',null,null));
		//if(tokeninfo);else return;
		//const user=getfirst(await uscall(appcontext,auth,'aaa','user','load',null,[tokeninfo.user]));
		const user=usercontext.user;
		setUserData(user);
		setCustomize(user.accept_customize);
		setMarketing(user.accept_marketing);
	}

    useEffect(() => {
	if((!userData) && usercontext && usercontext.user){
		loadUserData();
	}
    }, [userData,navigate,auth,appcontext,usercontext/*,loadUserData*/]);

    const [isSubmitting, setIsSubmitting] = useState(false);// used to avoid double submissions
    const handleSubmit=async function(e){
		e.preventDefault();
		if(isSubmitting)return;
        	setIsSubmitting(true);
		try{
			let r=await uscall(appcontext,auth,'aaa','user','save',null,[{uuid:userData.uuid,/*accept_customize:customize,*/accept_marketing:marketing}]);
			if(typeof(r['error'])!='undefined'){
            			notifyWarn(
            			    process.env.PUBLIC_URL+'alert.svg',
            			    'Errore',
            			    <>{r?.error?.description}</>,
            			);
			}else{
				notifySuccess('Preferenze aggiornate');
				reloadusercontext();
				//navigate('/');
			}
		}catch(err){
	            	notifyWarn(
	            	    process.env.PUBLIC_URL+'alert.svg',
	            	    'Errore di comunicazione',
	            	    <>Ti preghiamo di riprovare più tardi</>,
	            	);
		}
	        setIsSubmitting(false);

    };

    return (
        <div className="account-container">
            <div className="account-top">
                <div className="row h-100 align-items-center">
                    <PrevPage />
                    <div className="col-10 text-center">
                        <h1>Preferenze</h1>
                    </div>
                    <div className="col-1"></div>
                </div>
            </div>
            <div className="account-body">
                <form onSubmit={handleSubmit}>
                    <h2>Impostazioni sulla privacy</h2>
                    <div className='row'>
                        <div className='col-auto'>
                            <input type='checkbox' disabled checked={customize} onClick={(e)=>{/*setCustomize(e.target.checked?true:false)*/}} />
                        </div>
                        <div className='col'>
                            <h2 className="checkbox-description">Utilizzare i miei dati personali per personalizzare la mia esperienza con Giappichelli.</h2>
                        </div>
                    </div>
                    <h2>Comunicazioni marketing</h2>
                    <div className='row'>
                        <div className='col-auto'>
                            <input type='checkbox' checked={marketing} onClick={(e)=>{setMarketing(e.target.checked?true:false)}} />
                        </div>
                        <div className='col'>
                            <h2 className="checkbox-description">Cliccando accetti che i tuoi dati vengano utilizzati per inviarti offerte commerciali e marketing</h2>
                        </div>
                    </div>
                    <h2>Elimina utenza</h2>
                    <div className='row'>
                        <div className='col-12'>
                            <Link className="checkbox-description" to="/profile/delete-user/">Cancella il tuo account</Link>
                        </div>
                    </div>
                    <div className="row buttons-container">
                        <div className="col-md-6">
                            <input className="gradient-button reset-button" type="reset" value="Annulla" onClick={(e)=>{setCustomize(userData.accept_customize);setMarketing(userData.accept_marketing);}} />
                        </div>
                        <div className="col-md-6">
                            <input className="gradient-button" type="submit" value="Salva" />
                        </div>
                    </div>
                </form>
                    <ToastContainer className="custom-toast custom-toast-warning" />
            </div>
        </div>
    );
}

export default Preferences;
