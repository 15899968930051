import './landing.css';

function CardsLanding() {
   
    return (
        <section className='content-landing'>
            <h1>Le ultime pubblicazioni</h1>
            <div className="d-flex landing-cards">
                <div>
                    <img src={`${process.env.PUBLIC_URL}/cover1.jpg`} alt="Cover" />
                    <button className="chip"><span>Novità</span></button>
                    <div className="pricetag"><span>€ 21,00</span></div>
                </div>
                <div>
                    <img src={`${process.env.PUBLIC_URL}/cover1.jpg`} alt="Cover" />
                    <button className="chip"><span>Novità</span></button>
                    <div className="pricetag"><span>€ 21,00</span></div>
                </div>
                <div>
                    <img src={`${process.env.PUBLIC_URL}/cover1.jpg`} alt="Cover" />
                    <button className="chip"><span>Novità</span></button>
                    <div className="pricetag"><span>€ 21,00</span></div>
                </div>
                <div>
                    <img src={`${process.env.PUBLIC_URL}/cover1.jpg`} alt="Cover" />
                    <button className="chip"><span>Novità</span></button>
                    <div className="pricetag"><span>€ 21,00</span></div>
                </div>
            </div>
        </section>
    )
}

export default CardsLanding;
