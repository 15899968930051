import './header.css';
import './headerDash.css';
import HeaderMenu from '../../utilities/headerMenu';

function HeaderComponent() {
	//<div style={{display:'none'}}> DEBUG TOREMOVE {auth && auth.isAuthenticated ? (<>{auth.user?.profile.email} {auth.user?.profile.given_name} {auth.user?.profile.family_name} {(new Date(auth.user?.expires_at)).toString()} {auth.user?.access_token}</>) : (<>non loggato</>)}</div>
	return(

		<header>
			<HeaderMenu />
		</header>

	)

}

export default HeaderComponent;
