import { useAuth } from 'react-oidc-context';
import AppContext from '../../AppContext';
import { useEffect,useContext,useState/*,useRef*/ } from 'react';
import ContinueBox from './boxes/continue';
import PromoX1Box from './boxes/promoX1';
import RequestCopyBox from './boxes/requestCopy';
import YourProductsBox from './boxes/yourProd';
import { useNavigate } from 'react-router-dom';
import './dash.css';
import BooksForYou from './boxes/BooksForYou';
import RivisteForYou from './boxes/RivisteForYou';
import CorsiForYou from './boxes/CorsiForYou';
import {uscall,getfirst} from '../../us';
import UserContext from '../../UserContext';
import CommonContext from '../../CommonContext';

export default function Dashboard() {
    const auth = useAuth();
    let navigate = useNavigate();
    const appcontext = useContext(AppContext);
    const usercontext = useContext(UserContext);
        const commoncontext = useContext(CommonContext);
    useEffect(() => {
      if(auth.isAuthenticated);
      else 
        navigate('/login/?redirect='+encodeURIComponent(document.location.href));
    },[auth,navigate]);
    const [curuser,setCurUser]=useState(null);
    const [lastBuy,setLastBuy]=useState(null);
    const [lastProduct,setLastProduct]=useState(null);
	const [producttypes,setProductTypes]=useState(null);
    useEffect(() => {
	const cb=async function(){
		setProductTypes(commoncontext.producttypes);
	}
	if(producttypes);else if(commoncontext && commoncontext.producttypes)
		cb();
    },[auth,appcontext,commoncontext,producttypes]);
    useEffect(() => {
	const loadLastLocalOrder=async function(){
		let r2=await uscall(appcontext,auth,'order','order','myactiveorders',null,{sort:{field:'committed',desc:true},limit:1});
		if(r2.result.length>0){
		let status_tbl=[];
		for(let i=0;i<r2.result.length;i++){if(status_tbl.includes(r2.result[i].status));else status_tbl.push(r2.result[i].status);}
		let statuses=await uscall(appcontext,auth,'order','orderstatus','load',null,status_tbl);
		let items_tbl=[];
		for(let i=0;i<r2.result.length;i++)items_tbl=items_tbl.concat(r2.result[i].items);
		let items=await uscall(appcontext,auth,'order','orderitem','load',null,items_tbl);
		let products_tbl=[];
		for(let i in items.result)products_tbl.push(items.result[i].product);
		let products=await uscall(appcontext,auth,'pim','product','load',null,products_tbl);

		for(let i=0;i<r2.result.length;i++){
			let myitems=[];
			for(let j=0;j<r2.result[i].items.length;j++){
				let item=items.result[r2.result[i].items[j]];
				item.product=products.result[item.product];
				myitems.push(item);
			}
			r2.result[i].status=statuses.result[r2.result[i].status].code;
			r2.result[i].items=myitems;
			return r2.result[i];
		}
		}
		return null;
	};
	const loadLastRemoteOrder=async function(){
		const r=await uscall(appcontext,auth,'ecommerce',null,'order_getlastuserorder',null,null);
//console.log('lastorder:'+JSON.stringify(r));
		if(typeof(r['error'])!='undefined');
		else if(typeof(r['result'])!='undefined' && r.result){
			return r.result;
			//for(let i=0;i<r.result.length;i++){
			//	return r.result[i];
			//}
		}
		return null;
	};
	const cb=async function(){
		const l1=await loadLastLocalOrder();
		if(l1)setLastBuy(l1);// abbonamentoedu cannot be the last buy
		const l2=await loadLastRemoteOrder();
//console.log(l1);
		if(l2 && ((!l1) || l2.created>l1.created))setLastBuy(l2);

		/*const r=await uscall(appcontext,auth,'ecommerce',null,'order_getlastuserorder',null,null);
//console.log('lastorder:'+JSON.stringify(r));
		if(typeof(r['error'])!='undefined');
		else if(typeof(r['result'])!='undefined' && r.result)setLastBuy(r.result);*/
	}
	if(lastBuy && usercontext && usercontext.user);else 
		cb();
    },[auth,appcontext,usercontext,lastBuy]);
    useEffect(() => {
	if(lastBuy && typeof(lastBuy['items'])!='undefined'){
		for(let i=0;i<lastBuy.items.length;i++){
			if(lastBuy.items[i].product){
				setLastProduct(lastBuy.items[i].product);
				break;
			}
		}
	}
	},[lastBuy]);

    //const effectRan = useRef(false);// use to avoid in dev mode double rendering after redirect, causing NS_BINDING_ERR: 

    useEffect(() => {
	const checkPrimoAccesso=async function(){
		//const tokeninfo=(await uscall(appcontext,auth,'aaa','session','tokeninfo',null,null));
		//if(tokeninfo);else return;
		//const user=getfirst(await uscall(appcontext,auth,'aaa','user','load',null,[tokeninfo.user]));
		const user=usercontext.user;
		if(user.professione);else return navigate('/profilo/primoaccesso/');
		user.professionecode=getfirst(await uscall(appcontext,auth,'pim','professione','load',null,[user.professione])).code;
		setCurUser(user);
	};
	//if(!effectRan.current){
	if(usercontext && usercontext.user)
		checkPrimoAccesso();
	//	return () => effectRan.current = true;
	//}
    }, [appcontext,auth,usercontext,navigate]);

	const hasedu=(curuser && curuser.has_edu_upto && curuser.has_edu_upto>(new Date()).valueOf());
	let box1areacode=(curuser?(curuser.professionecode==='student'?'dashboard_'+(hasedu?'edu_':'')+'studenti_top_right':(curuser.professionecode==='professor'?'dashboard_'+(hasedu?'edu_':'')+'docenti_top_right':'dashboard_'+(hasedu?'edu_':'')+'professionisti_top_right')):null);
	let box2areacode=(curuser?(curuser.professionecode==='student'?'dashboard_'+(hasedu?'edu_':'')+'studenti_left':(curuser.professionecode==='professor'?'dashboard_'+(hasedu?'edu_':'')+'docenti_left':'dashboard_'+(hasedu?'edu_':'')+'professionisti_left')):null);
    return (
	<>
	{ auth.isAuthenticated ? (

        <div className='dash-container'>
            <div className='row g-0 mb-md-4'>
                <div className='col-md-8 mb-4 mb-md-0'>
                  <ContinueBox lastProduct={lastProduct} professionecode={curuser?curuser.professionecode:null} producttypes={producttypes} />
		</div>
                <div className='col-md-4 mb-4 mb-md-0'>
	                  <PromoX1Box areacode={box1areacode} />
		</div>
            </div>
		{lastBuy?(
            <div className='row g-0 mb-md-4'>
                <div className='col-md-4 mb-4 mb-md-0'>
	                  <PromoX1Box areacode={box2areacode} />
		</div>
                <div className='col-md-8 mb-4 mb-md-0'>
                  <YourProductsBox />
		</div>
            </div>):(<></>)}
            <div className='row g-0 mb-md-4'>
		{curuser && curuser.professionecode?(
		<>
	                <RequestCopyBox areacode={curuser.professionecode==='student'?'dashboard_studenti_1':(curuser.professionecode==='professor'?'dashboard_docenti_1':'dashboard_professionisti_1')} />
	                <RequestCopyBox areacode={curuser.professionecode==='student'?'dashboard_studenti_2':(curuser.professionecode==='professor'?'dashboard_docenti_2':'dashboard_professionisti_2')} />
	                <RequestCopyBox areacode={curuser.professionecode==='student'?'dashboard_studenti_3':(curuser.professionecode==='professor'?'dashboard_docenti_3':'dashboard_professionisti_3')} />
	                <RequestCopyBox areacode={curuser.professionecode==='student'?'dashboard_studenti_4':(curuser.professionecode==='professor'?'dashboard_docenti_4':'dashboard_professionisti_4')} />
		</>
		):''}
            </div>
		<>{curuser && (
            <div className='row g-0'>
                <div className='col-12 mb-4'>
                  <BooksForYou professionecode={curuser.professionecode} />
		</div>
            </div>)}</>
		<>{curuser && curuser.professionecode && curuser.professionecode!=='student' && (
            <div className='row g-0'>
                <div className='col-12 mb-4'>
                  <RivisteForYou />
		</div>
            </div>
		)}</>
		<>{curuser && curuser.professionecode && curuser.professionecode!=='professor' && (
            <div className='row g-0'>
                <div className='col-12 mb-4'>
                  <CorsiForYou />
		</div>
            </div>
		)}</>
        </div>
	):(<></>)}
	</>

    )

};

