import { useState,useContext,useEffect/*,useRef*/ } from 'react';
import { useAuth } from 'react-oidc-context';
import AppContext from '../../../AppContext';
import { useNavigate } from 'react-router-dom';
import './primoaccesso.css';
import {uscall/*,getfirst*/} from '../../../us';
import { notifyWarn,notifySuccess } from '../../utilities/toaster';
import { ToastContainer } from 'react-toastify';
import UserContext from '../../../UserContext';

const PrimoAccesso=function ({reloadusercontext}) {
    const auth = useAuth();
    const appcontext = useContext(AppContext);
    const usercontext = useContext(UserContext);
    let navigate = useNavigate();
    useEffect(() => {
      if(auth.isAuthenticated);
      else 
        navigate('/login/?redirect='+encodeURIComponent(document.location.href));
    },[auth,navigate]);

    const [userData,setUserData]=useState(null);
    const [curuserData,setCurUserData]=useState(null);
    const [professioneStudente,setProfessioneStudente]=useState(null);
    const [professioneProfessore,setProfessioneProfessore]=useState(null);
    const [professioni,setProfessioni]=useState(null);
    const [interessi,setInteressi]=useState(null);
    const [materie,setMaterie]=useState(null);
    const [universita,setUniversita]=useState(null);
    const [corsilaurea,setCorsiLaurea]=useState(null);
    const [changes,setChanges]=useState({});

    //const effectRan = useRef(false);// use to avoid in dev mode double rendering after redirect, causing NS_BINDING_ERR: 

    const loadCorsiLaurea=async function(universita){
	if(universita){
		setCorsiLaurea((await uscall(appcontext,auth,'pim',null,'getall-corsolaurea',null,{filter:{field:'universita',func:'eq',value:universita},sort:{field:'name',desc:false}})).result);
	}else
		setCorsiLaurea([]);
    };
	const updateField=async function(field,value){
		if(curuserData && professioneStudente && professioneProfessore);else return;
		if(curuserData.professione===professioneStudente.uuid){
			if(field==='universita'){
				loadCorsiLaurea(value);
			}
		}

		let nc={...changes};nc[field]=value;
		setChanges(nc);
		let nud={...curuserData};nud[field]=value;
		setCurUserData(nud);
	}
	const update2Field=async function(field1,value1,field2,value2){
		if(curuserData && professioneStudente && professioneProfessore);else return;
		if(curuserData.professione===professioneStudente.uuid){
			if(field1==='universita'){
				loadCorsiLaurea(value1);
			}
		}

		let nc={...changes};nc[field1]=value1;nc[field2]=value2;
		setChanges(nc);
		let nud={...curuserData};nud[field1]=value1;nud[field2]=value2;
		setCurUserData(nud);
	}
	const loadUserData=async function(){
		//const tokeninfo=(await uscall(appcontext,auth,'aaa','session','tokeninfo',null,null));
		//if(tokeninfo);else return;
		//const user=getfirst(await uscall(appcontext,auth,'aaa','user','load',null,[tokeninfo.user]));
		const user=usercontext.user;
		setUserData(user);
		setCurUserData({...user});
	}

    useEffect(() => {
	if((!userData) && usercontext && usercontext.user){
		//if(!effectRan.current){
			loadUserData();
		//	return () => effectRan.current = true;
		//}
	}else{
		if(userData && userData.professione)navigate('/');
	}
    }, [userData,navigate,auth,appcontext,usercontext/*,loadUserData*/]);

    useEffect(() => {
	const cb=async function(){setProfessioni((await uscall(appcontext,auth,'pim',null,'getall-professione',null,{sort:{field:'position',desc:false}})).result);};
	if(!professioni)cb();
    }, [professioni,appcontext,auth]);
    useEffect(() => {
	if(professioni && (!professioneStudente)){
		for(let i=0;i<professioni.length;i++){
			if(professioni[i].code==='student'){
				setProfessioneStudente(professioni[i].uuid);
				break;
			}
		}
	}
    }, [professioni,professioneStudente]);
    useEffect(() => {
	if(professioni && (!professioneProfessore)){
		for(let i=0;i<professioni.length;i++){
			if(professioni[i].code==='professor'){
				setProfessioneProfessore(professioni[i].uuid);
				break;
			}
		}
	}
    }, [professioni,professioneProfessore]);
    useEffect(() => {
	const cb=async function(){setInteressi((await uscall(appcontext,auth,'pim',null,'getall-interesse',null,{sort:{field:'name',desc:false}})).result);};
	if(!interessi)cb();
    }, [interessi,appcontext,auth]);
    useEffect(() => {
	const cb=async function(){setMaterie((await uscall(appcontext,auth,'pim',null,'getall-materia',null,{sort:{field:'name',desc:false}})).result);};
	if(!materie)cb();
    }, [materie,appcontext,auth]);
    useEffect(() => {
	const cb=async function(){setUniversita((await uscall(appcontext,auth,'pim',null,'getall-universita',null,{sort:{field:'name',desc:false}})).result);};
	if(!universita)cb();
    }, [universita,appcontext,auth]);
    useEffect(() => {
	if(curuserData && (!corsilaurea))loadCorsiLaurea(curuserData.universita);
    }, [corsilaurea,curuserData/*,loadCorsiLaurea*/]);

    const [selectedProfessione,setSelectedProfessione]=useState(null);
    const [selectedInteressi,setSelectedInteressi]=useState([]);
    const [selectedUniversita,setSelectedUniversita]=useState(null);
    const [selectedCorsoLaurea,setSelectedCorsoLaurea]=useState(null);
    const [selectedMateria,setSelectedMateria]=useState(null);
    const [commercial,setCommercial]=useState(null);

    //const [isSubmitted, setIsSubmitted] = useState(false);// used to raise warnings
    const [isSubmitting, setIsSubmitting] = useState(false);// used to avoid double submissions

    const handleSubmit=async function(e){
		if(isSubmitting)return;
        	//setIsSubmitted(true);
        	setIsSubmitting(true);
		try{
			let r=await uscall(appcontext,auth,'aaa','user','save',null,[{...changes,uuid:userData.uuid,accept_marketing:true}]);
			if(typeof(r['error'])!='undefined'){
            			notifyWarn(
            			    process.env.PUBLIC_URL+'alert.svg',
            			    'Errore',
            			    <>{r?.error?.description}</>,
            			);
			}else{
				notifySuccess('Profilo aggiornato');
				await reloadusercontext();
				navigate('/');
			}
		}catch(err){
	            	notifyWarn(
	            	    process.env.PUBLIC_URL+'alert.svg',
	            	    'Errore di comunicazione',
	            	    <>Ti preghiamo di riprovare più tardi</>,
	            	);
		}
	        setIsSubmitting(false);
	        //setIsSubmitted(false);

    };
    useEffect(() => {
	if(professioneStudente && professioneProfessore){
		if(userData && curuserData){
			if(curuserData.professione===professioneStudente){
				if(curuserData.universita && curuserData.corsolaurea)handleSubmit();
			}else if(curuserData.professione===professioneProfessore){
				if(curuserData.universita && curuserData.materia)handleSubmit();
			}else if(curuserData.professione){
				if(curuserData.interessi && curuserData.interessi.length>0)handleSubmit();
			}
		}
	}
    }, [userData,curuserData,professioneStudente,professioneProfessore/*,handleSubmit*/]);

    return (
	<>
	{ auth.isAuthenticated ? (
        <div className='page w-100'>
            <div className='row h-100 g-0'>
                <div className='col-6 page-background'></div>
                <div className='col-6 page-right'>
                    <h1>Personalizza la tua esperienza<br />con Giappichelli</h1>
                    <p>Completa il profilo inserendo la tua professione e i tuoi interessi e scopri la<br />
                        nostra offerta editoriale digitale.</p>
                    <div className='spacer'></div>
                    <form>
			{curuserData && professioneStudente && professioneProfessore?(<>
				{curuserData.professione?(
					<>{curuserData.professione===professioneStudente || curuserData.professione===professioneProfessore?(<>
					            <div className='custom-select-container'>
					                <label htmlFor="universita" className='form-label'>Universita *</label>
					                <select onChange={(e)=>{setSelectedUniversita(e.target.value);loadCorsiLaurea(e.target.value);}}>
					                    <option value=''>Seleziona</option>
					                    {universita.map(option => (
					                        <option value={option.uuid} selected={selectedUniversita===option.uuid}>
					                            {option.name}
					                        </option>
					                    ))}
					                </select>
					            </div>
						{selectedUniversita ?(
							<>{curuserData.professione===professioneStudente?(<>
						            <div className='custom-select-container'>
						                <label htmlFor="corsolaurea" className='form-label'>Corso di Laurea *</label>
						                <select onChange={(e)=>{setSelectedCorsoLaurea(e.target.value);setCommercial(false);}}>
						                    <option value=''>Seleziona</option>
						                    {corsilaurea.map(option => (
						                        <option value={option.uuid} selected={selectedCorsoLaurea===option.uuid}>
						                            {option.name}
						                        </option>
						                    ))}
						                </select>
						            </div>
						            <div>
								{selectedCorsoLaurea && (
						                    <button className='gradient-button' type="button" onClick={(e)=>{e.preventDefault();setCommercial(true);update2Field('universita',selectedUniversita,'corsolaurea',selectedCorsoLaurea);}}>Completa</button>
								)}
						            </div>
								
								{selectedCorsoLaurea && (
						                        <div className='row'>
						                            <div className='col-auto'>
						                                <input type='checkbox' checked={commercial} />
						                            </div>
						                            <div className='col'>
						                                <h5>Continuando accetti di ricevere da G. Giappichelli Editore<br />informazioni commerciali e pubblicitarie definite in base agli<br /> interessi. consulta la nostra <a href={`${process.env.REACT_APP_PREFIX_MAGENTO}/privacy`}>Informativa sulla Pubblicità</a></h5>
						                            </div>
						                        </div>
								)}
							</>):(<>
						            <div className='custom-select-container'>
						                <label htmlFor="materia" className='form-label'>Materia *</label>
						                <select onChange={(e)=>{setSelectedMateria(e.target.value);setCommercial(false);}}>
						                    <option value=''>Seleziona</option>
						                    {materie.map(option => (
						                        <option value={option.uuid} selected={selectedMateria===option.uuid}>
						                            {option.name}
						                        </option>
						                    ))}
						                </select>
						            </div>
						            <div>
								{selectedMateria && (
						                    <button className='gradient-button' type="button" onClick={(e)=>{e.preventDefault();setCommercial(true);update2Field('universita',selectedUniversita,'materia',selectedMateria);}}>Completa</button>
								)}
						            </div>
								{selectedMateria && (
						                        <div className='row'>
						                            <div className='col-auto'>
						                                <input type='checkbox' checked={commercial} />
						                            </div>
						                            <div className='col'>
						                                <h5>Continuando accetti di ricevere da G. Giappichelli Editore<br />informazioni commerciali e pubblicitarie definite in base agli<br /> interessi. consulta la nostra <a href={`${process.env.REACT_APP_PREFIX_MAGENTO}/privacy`}>Informativa sulla Pubblicità</a></h5>
						                            </div>
						                        </div>
								)}
							</>)}</>
						):(<></>)}
					</>):(<>
				            <div className='custom-select-container'>
				                <label htmlFor="interessi" className='form-label'>Interessi *</label>
				                <div>
				                    {interessi.map(interesse => (
				                        <div className='chip' onClick={(e) => {setCommercial(false);setSelectedInteressi(selectedInteressi.includes(interesse.uuid)?selectedInteressi.filter((uuid)=>{return (uuid!==interesse.uuid);}):[...selectedInteressi,interesse.uuid]);}}>
				                            <span className={selectedInteressi.includes(interesse.uuid) ? 'chip-icon chip-checked' : 'chip-icon'}></span>
				                            <p>{interesse.name}</p>
				                        </div>
				                    ))}
				                </div>
				            </div>
				            <div>
				                {selectedInteressi && selectedInteressi.length>0 && (
				                    <button className='gradient-button' type="button" onClick={(e)=>{e.preventDefault();setCommercial(true);updateField('interessi',selectedInteressi);}}>Completa</button>
				                )}
				            </div>
				                {selectedInteressi && selectedInteressi.length>0 && (
				                        <div className='row'>
				                            <div className='col-auto'>
				                                <input type='checkbox' checked={commercial} />
				                            </div>
				                            <div className='col'>
				                                <h5>Continuando accetti di ricevere da G. Giappichelli Editore<br />informazioni commerciali e pubblicitarie definite in base agli<br /> interessi. consulta la nostra <a href={`${process.env.REACT_APP_PREFIX_MAGENTO}/privacy`}>Informativa sulla Pubblicità</a></h5>
				                            </div>
				                        </div>
						)}
					</>)}</>
				):(<>
			            <div className='custom-select-container'>
			                <label htmlFor="professione" className='form-label'>Professione *</label>
			                <select onChange={(e)=>{setSelectedProfessione(e.target.value);}}>
			                    <option value=''>Seleziona</option>
			                    {professioni.map(option => (
			                        <option value={option.uuid} selected={selectedProfessione===option.uuid}>
			                            {option.name}
			                        </option>
			                    ))}
			                </select>
			            </div>
			            <div>
			                {selectedProfessione && (
			                    <button className='gradient-button' type="button" onClick={(e)=>{e.preventDefault();updateField('professione',selectedProfessione);}}>Procedi</button>
			                )}
			            </div>
				</>)}
			</>):(<></>)}
                    </form>
                </div>
            </div>
                    <ToastContainer className="custom-toast custom-toast-warning" />
        </div>
	):(<></>)}
	</>
    );
}

export default PrimoAccesso;
