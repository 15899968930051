import ActivateLanding from './activate-landing';
import BannerLanding from './banner-landing';
import BoxLanding from './box-landing';
import CardsLanding from './cards-landing';
import CollaborateLanding from './collaborate-landing';
import './landing.css';
import NewsLanding from './news-landing';

function Landing() {
   
    return (
        <>
            <BannerLanding />
            <CardsLanding />
            <div className='divider-landing'>
                <hr />
                <div>
                    <a href="#">Scopri tutte le novità <img src={`${process.env.PUBLIC_URL}/icon-arrow-blue.svg`} alt="Scopri tutte le novità" /></a>
                </div>
                <hr />
            </div>
            <NewsLanding />
            <ActivateLanding />
            <CollaborateLanding />
        </>
    )
}

export default Landing;
