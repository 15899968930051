

const CheckoutContact=function({orderinfo}){
	return (<>
                                        <h2>Informazioni di contatto</h2>
                                        <>
                                            <div className='row'>
                                                <div  className={`col-6`}>
                                                    <label htmlFor="firstname" className='form-label'>Nome *</label>
                                                    <input
                                                    type='text'
                                                    name="firstname"
                                                    value={orderinfo.customer_firstname}
                                                    readOnly
                                                    />
                                                </div>
                                                <div className={`col-6`}>
                                                    <label htmlFor="lastname" className='form-label'>Cognome *</label>
                                                    <input
                                                    type='text'
                                                    name="lastname"
                                                    value={orderinfo.customer_lastname}
                                                    readOnly
                                                    />
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className={`col-6 `}>
                                                    <label htmlFor="email" className='form-label'>Email *</label>
                                                    <input
                                                    type="email"
                                                    name="email"
                                                    value={orderinfo.customer_email}
                    				    readOnly
                                                    />
                                                </div>
                                            </div>
                                        </>
</>);

};
export default CheckoutContact;
