export default function CardCorso({imageurl,title,authors,type,description,url}){
	return (
	<div className="foryou-alt">
		<div className='prod-cover'>
			<a href={url}>
				<img src={imageurl?imageurl:`${process.env.PUBLIC_URL}/placeholder.png`} alt={title} />
				<div>
					<h6>{title}</h6>
				</div>
				<p>{authors}</p>
				{/*<p>{type}</p>
				<p>{description}</p>*/}
			</a>
		</div>
	</div>
	);
};
