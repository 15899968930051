import {SinglePayment} from './payment';
import { useEffect,useState,useContext } from 'react';
import { useAuth } from 'react-oidc-context';
import AppContext from '../../AppContext';
//import UserContext from '../../UserContext';
import CommonContext from '../../CommonContext';
import { useParams } from 'react-router-dom';
import './myproducts.css';
import {uscall/*,getresourceurl*/,getfirst/*,usurl,mergeorders*/} from '../../us';
import { useNavigate} from "react-router-dom";

const Ricevuta=()=>{
    const auth = useAuth();
        const commoncontext = useContext(CommonContext);
    //const usercontext = useContext(UserContext);
    const appcontext = useContext(AppContext);
	const [producttypes,setProductTypes]=useState(null);
	const [order,setOrder]=useState(null);
	const [payment,setPayment]=useState(null);
    const {ordercode,paymentcode}=useParams();
	const navigate=useNavigate();
    useEffect(() => {
	const cb=async function(){
		setProductTypes(commoncontext.producttypes);
	}
	if(producttypes);else if(commoncontext && commoncontext.producttypes)
		cb();
    },[auth,appcontext,commoncontext,producttypes]);
    useEffect(() => {
		const cb=async function(){
			if(!paymentcode){
				const r=await uscall(appcontext,auth,'ecommerce',null,'order_getlastuserorders',null,{ordercode:ordercode});
				if(typeof(r['error'])!='undefined');
				else if(typeof(r['result'])!='undefined' && r.result){
					for(let k=0;k<r.result.length;k++){
						setOrder(r.result[k]);
						setPayment(r.result[k].payment);
						break;
					}
				}
			}else{
				const order=getfirst(await uscall(appcontext,auth,'order','order','load',null,[ordercode]));
				const items=(await uscall(appcontext,auth,'order','orderitem','load',null,[order.items]));
				let ps=[];
				for(let k in items.result)ps.push(items.result[k].product);
				const products=(await uscall(appcontext,auth,'pim','product','load',null,ps));
				order.items=[];
				for(let k in items.result){
					let myitem=items.result[k];
					myitem.product=products.result[myitem.product];
					myitem.product.type=producttypes[myitem.product.type].code;
					order.items.push(myitem);
				}
				setOrder(order);
			}
		}
		if(producttypes && (!order))cb();
	},[auth,appcontext,order,ordercode,paymentcode,producttypes]);
    useEffect(() => {
		const cb=async function(){
			if(!paymentcode){
			}else{
				let payment=getfirst(await uscall(appcontext,auth,'order','orderpayment','load',null,[paymentcode]));
				setPayment(payment);
			}
		}
		if(!order)cb();
	},[auth,appcontext,order,ordercode,paymentcode]);
    useEffect(() => {
		if(order && producttypes && payment){
			window.print();
			navigate(-1);
		}
	},[order,producttypes,payment,navigate]);
	return (
        	<div className='purchase-history'>
			<>{order && producttypes && payment?(<SinglePayment order={order} producttypes={producttypes} payment={payment} />):null}</>
		</div>
);
	
}

export default Ricevuta;
