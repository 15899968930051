export const downloadmanifestfrom=function(manifests){
	return new Promise(async function(resolve,reject){
		let tbd=[];
		let tbdurl=[];
		for(let k in manifests){
			for(let o in manifests[k].relations){
				if(typeof(manifests[manifests[k].relations[o]])!='undefined' || tbdurl.includes(manifests[k].relations[o]));else{
					tbd.push(fetch(manifests[k].relations[o]));
					tbdurl.push(manifests[k].relations[o]);
				}
			}
		}
		Promise.all(tbd).then(async function(ms){
			for(let k in ms){
				let m=await ms[k].json();
				if(typeof(manifests[m.code])!='undefined');else manifests[m.code]=m;
			}
			resolve(manifests);
		},function(er){reject(er);});
	});
};
export const uscall=async function(newconfig,auth,origin,modelname,callname,get,post){
	let call=newconfig.manifests[origin?origin:newconfig.manifest.code];
	if(modelname){
		if(typeof(call.schema[modelname])!='undefined')
			call=call.schema[modelname].api;
		else{
			console.log('Error, cannot find '+modelname+' in manifest '+(origin?origin:newconfig.manifest.code));console.log(call);
		}
	}else call=call.api;
	if(typeof(call[callname])!='undefined');else{
		console.log('Error, cannot find '+callname+' in manifest '+(origin?origin:newconfig.manifest.code));console.log(call);
	}
	let url=call[callname].url;
	if(url.indexOf(':')>=0){
		url=url.substr(0,url.indexOf(':'));
		if(get)url+=get;
	}else{
		if(get)
			url+='?'+get;
	}
	const baseurl=newconfig.manifests[origin?origin:newconfig.manifest.code].baseurl;
	//url=baseurl.substr(0,baseurl.length-'/api/'.length)+url;
	url=baseurl+url;
	let params={method:call[callname].method,headers:{'content-type':'application/json','authorization':'Bearer '+auth.user?.access_token}};
	if(call[callname].method==='POST')params.body=JSON.stringify(post);

	const c=await fetch(url,params);
	return await c.json();
};
export const usurl=async function(newconfig,auth,origin,modelname,callname,get){
	if(newconfig && typeof(newconfig['manifests'])!='undefined' && typeof(newconfig.manifests[origin?origin:newconfig.manifest.code])!='undefined');else{console.log('missing manifest for '+(origin?origin:newconfig.manifest.code));return null;}
	let call=newconfig.manifests[origin?origin:newconfig.manifest.code];
	if(modelname){
		if(typeof(call.schema[modelname])!='undefined');else{console.log('missing model '+modelname+' in '+(origin?origin:newconfig.manifest.code));return null;}
		call=call.schema[modelname].api;
	}else call=call.api;
	let url=call[callname].url;
	if(url.indexOf(':')>=0){
		url=url.substr(0,url.indexOf(':'));
		if(get)url+=get;
	}else{
		if(get)
			url+='?'+get;
	}
	const baseurl=newconfig.manifests[origin?origin:newconfig.manifest.code].baseurl;
	//url=baseurl.substr(0,baseurl.length-'/api/'.length)+url;
	url=baseurl+url;
	return url;
};
const _getresourceurl=async function(newconfig,auth,uuid,resolve){
	/*if(newconfig && newconfig.manifest && typeof(newconfig['manifests'])!='undefined' && typeof(newconfig.manifests[newconfig.manifest.code])!='undefined');else{
		console.log('missing manifest for '+(newconfig.manifest.code));
		setTimeout(function(){return function(newconfig,auth,uuid,resolve){_getresourceurl(newconfig,auth,uuid,resolve);};}(newconfig,auth,uuid,resolve),5000);
		return;
	}*/
	resolve(await usurl(newconfig,auth,'media','resource','download',uuid));
};
export const getresourceurl=async function(newconfig,auth,uuid){
	return new Promise(function(resolve,reject){
		if(uuid){
			_getresourceurl(newconfig,auth,uuid,resolve);
			//setTimeout(function(){return function(newconfig,auth,uuid,resolve){_getresourceurl(newconfig,auth,uuid,resolve);};}(newconfig,auth,uuid,resolve),500);
		}else
			resolve(null);//reject();

	});
};
export const getfirst=function(r){
	if(typeof(r['error'])!='undefined')return null;
	if(typeof(r['result'])=='undefined')return null;
	for(let k in r.result){
		return r.result[k];
	}
	return null;
};

// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Text_formatting
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat
export const formatprice=function(p){
	return '€ '+(new Intl.NumberFormat('it-IT',{style:'decimal',minimumIntegerDigits:1,minimumFractionDigits:2}).format(p));
};
export const formatdate=function(p){
	const dateo=new Date(p);
	const months=['Gennaio','Febbraio','Marzo','Aprile','Maggio','Giugno','Luglio','Agosto','Settembre','Ottobre','Novembre','Dicembre'];
	return dateo.getDate()+' '+months[dateo.getMonth()]+' '+dateo.getFullYear();
};

export const translateproducttype=function(producttypes,type){
	if(producttypes);else return null;
	if(type);else return null;
	if(typeof(producttypes[type])!='undefined')return producttypes[type].name;
	for(let i in producttypes){
		if(producttypes[i].code===type)return producttypes[i].name;
	}
	return null;
};
export const translatepaymentmethod=function(method){
	if(method==='stripecheckout' || method==='stripesetup')return 'Carta di Credito';
	else if(method==='sepa')return 'Bonifico bancario';
	else return method;
};

export const mergeorders=function(o1,o2){
	return o1.concat(o2).sort(function(a,b){if(a.created>b.created)return -1;else if(a.created<b.created)return 1;else return 0;})// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/sort
};
