
import { useAuth } from 'react-oidc-context';
import AppContext from '../../AppContext';
import {uscall/*,getfirst*/,formatprice} from '../../us';
import { useState,useEffect,useContext} from 'react';
import Collapse from 'react-bootstrap/Collapse';

const CheckoutSidebar=function({orderinfo,orderitems,producttypes,loadOrderInfo}){
    const [open, setOpen] = useState(false);
    const [discountCode, setDiscountCode] = useState('');
    const [isDiscountApplied, setIsDiscountApplied] = useState(null);
    const [itemlist,setItemList] = useState(null);
	const auth = useAuth();
	const appcontext = useContext(AppContext);

	useEffect(()=>{
		const cb=function(){
			let il=[];
			for(let k in orderitems)il.push(orderitems[k]);
			setItemList(il);
		}
		if(orderitems && (!itemlist))cb();
	},[itemlist,orderitems]);

	useEffect(()=>{
		if(orderinfo && isDiscountApplied===null){
			if(orderinfo.couponcode){
				setDiscountCode(orderinfo.couponcode_code);
				setIsDiscountApplied(true);
        			setOpen(true);
			}else{
				setIsDiscountApplied(false);
			}
		}
	},[orderinfo,isDiscountApplied]);

    const handleApplyDiscount = async function(event){
	event.stopPropagation();
	event.preventDefault();
        if (discountCode.trim() !== '') {
		const r=(await uscall(appcontext,auth,'order','order','order_addcoupon',encodeURIComponent(orderinfo.uuid)+'/'+encodeURIComponent(discountCode.trim())));
		if(r && typeof(r['error'])=='undefined' && r.result){
			await loadOrderInfo();
	            setIsDiscountApplied(true);
		}
        }
    };

    const handleRemoveDiscount = async function(event){
	event.stopPropagation();
	event.preventDefault();
	const r=(await uscall(appcontext,auth,'order','order','order_rmcoupon',encodeURIComponent(orderinfo.uuid)+'/'+encodeURIComponent(discountCode.trim())));
	if(r && typeof(r['error'])=='undefined' && r.result){
			await loadOrderInfo();
        	setIsDiscountApplied(false);
        	setDiscountCode('');
        	setOpen(false);
	}
    };

	return (<>
                    <h4>Riepilogo Ordine</h4>
                    <div className='divider-row'></div>
			<>{itemlist?(<>{itemlist.map(item=>{
				return (
                            <div className='row checkout-item'>
                                <div className='col'>
                                    <p>{item.quantity}x {item.description}</p>
                                    <span>{producttypes && item.producttype?producttypes[item.producttype].name:''}</span>
                                </div>
                                <div className='col-auto'>
                                    <p>{orderinfo && orderinfo.has_option_advance?(<>
					 {orderinfo.option_advance?formatprice(orderinfo.shown_total_option_advance_true):formatprice(item.monthlyprice)}
					</>):(<>{formatprice(item.total)}</>)}</p>
                                </div>
                            </div>);
			})}</>):(<></>)}</>

                    <div className='divider-row'></div>
			<>{orderinfo?(
                    <div className='row checkout-item'>
                        <div className='col'>
                            <p>Subtotale {itemlist?(<>({itemlist.length})</>):(<></>)}</p>
                        </div>
                        <div className='col-auto'>
                            <p>{orderinfo.has_option_advance?(<>
					{orderinfo.option_advance?(<>
						{formatprice(orderinfo.shown_total_option_advance_true)}
					</>):(<>
						{formatprice(orderinfo.shown_total_option_advance_false)}
					</>)}
				</>):(<>
					{formatprice(orderinfo.shown_total_option_advance_true)}
				</>)}</p>
                        </div>
                    <div className='row checkout-item'>
                        <div className='col'>
                            <p>Spese di spedizione</p>
                            <span>Consegna gratuita in 48 ore</span>
                        </div>
                        <div className='col-auto'>
                            <p>{formatprice(orderinfo.shipment_price)}</p>
                        </div>
                    </div>
                    <div className='divider-row'></div>
                    <div className='row checkout-item'>
                        <div className='col'>
                            <p>Codice sconto</p>
                        </div>
                        <div className='col-auto'>
                        <img
                            className={`toggle-arrow ${open ? 'rotate' : ''}`} 
                            onClick={() => setOpen(!open)}
                            aria-controls="collapseCode"
                            aria-expanded={open} 
                            src={`${process.env.PUBLIC_URL}/arrow-down.svg`} 
                            alt="Inserisci codice sconto" />
                        </div>
                    </div>
                    {!isDiscountApplied && (
                    <Collapse in={open}>
                        <div className='row checkout-item collapse' id='collapseCode'>
                            <div className='col'>
                                <input 
                                    placeholder='inserisci il codice' 
                                    value={discountCode}
                                    onChange={(e) => setDiscountCode(e.target.value)}
                                />
                            </div>
                            <div className='col-auto'>
                                <input 
                                    name='discount' 
                                    type='submit' 
                                    className='button gradient-button' 
                                    value="Applica" 
                                    onClick={handleApplyDiscount}
                                />
                            </div>
                        </div>
                    </Collapse>
                    )}
                    {isDiscountApplied && (
                    <div className='discount-container'>
                        <div className='row checkout-item'>
                            <div className='col'>
                                <div className='row g-0 m-auto w-100 align-items-center discount-applied'>
                                    <div className='col-auto'>
                                        <img src={`${process.env.PUBLIC_URL}/discount.svg`} />
                                    </div>
                                    <div className="col">
                                        <span>Codice sconto applicato</span>
                                        <p>{discountCode}</p>
                                    </div>
                                    <div className='col-auto'>
                                        <img 
                                            src={`${process.env.PUBLIC_URL}/close-x.svg`} 
                                            onClick={handleRemoveDiscount}
                                            alt="Rimuovi sconto"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='row checkout-item'>
                            <div className='col'>
                                <p>Sconto</p>
                            </div>
                            <div className='col-auto'>
                                <p>{orderinfo.has_option_advance?(<>
					{orderinfo.option_advance?(<>
						{formatprice(orderinfo.shown_discount_option_advance_true)}
					</>):(<>
						{formatprice(orderinfo.shown_discount_option_advance_false)}
					</>)}
				</>):(<>
					{formatprice(orderinfo.shown_discount_option_advance_true)}
				</>)}</p>
                            </div>
                        </div>                       
                    </div>
                    )}
                    <div className='divider-row'></div>
                    <div className='row checkout-item'>
                        <div className='col'>
                            <h2>Totale</h2>
                        </div>
                        <div className='col-auto'>
                            <h2>{orderinfo.has_option_advance?(<>
					{orderinfo.option_advance?(<>
						{formatprice(orderinfo.shown_total_option_advance_true+orderinfo.shown_discount_option_advance_true+orderinfo.shipment_price)}
					</>):(<>
						{formatprice(orderinfo.shown_total_option_advance_false+orderinfo.shown_discount_option_advance_false+orderinfo.shipment_price)}
					</>)}
				</>):(<>
					{formatprice(orderinfo.shown_total_option_advance_true+orderinfo.shown_discount_option_advance_true+orderinfo.shipment_price)}
				</>)}</h2>
                        </div>
                    </div>
                    </div>):(<></>)}</>

</>);
};
export default CheckoutSidebar;
