import SelectedContentBox from './selectedContent';
import CardBook from './CardBook';
import { useEffect, useState,useContext/*,useRef*/ } from 'react';
import { useAuth } from 'react-oidc-context';
import AppContext from '../../../AppContext';
import {uscall/*,getfirst*/} from '../../../us';
//import UserContext from '../../../UserContext';
import CommonContext from '../../../CommonContext';

export default function BooksForYou({professionecode}){
    const auth = useAuth();
    const appcontext = useContext(AppContext);
    //const usercontext = useContext(UserContext);
        const commoncontext = useContext(CommonContext);

	const [producttype_book,setProductTypeBook]=useState(null);
    const [products,setProducts]=useState(null);
	const now=(new Date()).valueOf();
    useEffect(() => {
	const cb=async function(){
		let bs=[];
		for(let i in commoncontext.producttypes){
			if(['corso','rivista'].includes(commoncontext.producttypes[i].code));else bs.push(commoncontext.producttypes[i].uuid);
		}
		setProductTypeBook(bs);
	}
	if((!producttype_book) && commoncontext && commoncontext.producttypes)
		cb();
    },[auth,appcontext,commoncontext,producttype_book]);

    useEffect(() => {
	const cb=async function(){
		//let filter={func:'and',fields:[{field:'type',func:'in',value:producttype_book},{field:'available',func:'is',value:true},{field:'pubdate',func:'gte',value:now}]};
		//if(['student','professor'].includes(professionecode))filter={func:'and',fields:[filter,{field:'book_isstudenti',func:'is',value:true}]};
		//else filter={func:'and',fields:[filter,{field:'book_isprofessionilegali',func:'is',value:true}]};
		//const ps=await uscall(appcontext,auth,'ecommerce',null,'product_search',null,{filter:filter,limit:20,sort:{field:'pubdate',desc:true}});
		const ps=await uscall(appcontext,auth,'ecommerce',null,'magento_getbooks',null,{additionalfilters:(professionecode==='student' || professionecode==='professor'?{student:1}:{legal_professions:1}),limit:20});
		if(typeof(ps['error'])!='undefined')return;
		setProducts(ps.result);
	}
	if(products);else if(producttype_book) cb();
    },[auth,appcontext,/*usercontext,*/products,now,producttype_book,professionecode]);


	//const pd=new Date('2024-01-01');
	return (<>
			{products ?(
				<>{products.length>0?(
		<SelectedContentBox title='Libri per te' allurl={`${process.env.REACT_APP_PREFIX_MAGENTO}/novita`}>
				{products.map(product=>(
					<CardBook key={product.uuid?product.uuid:product.magento_product_id} isnew={product.pubdate-now<7*24*60*60*1000} title={product.name} authors={product.shortauthors} edition={product.edizione} pubdate={new Date(product.pubdate)} url={product.url} imageurl={product?.magento_custom?.image} />
				))}
		</SelectedContentBox>
				):(
					<></>
				)}</>
			):(
		<SelectedContentBox title='Libri per te' allurl={`${process.env.REACT_APP_PREFIX_MAGENTO}/novita`}>
				<CardBook key="fake1" />
				<CardBook key="fake2" />
				<CardBook key="fake3" />
				<CardBook key="fake4" />
				<CardBook key="fake5" />
				<CardBook key="fake6" />
		</SelectedContentBox>
			)}
	</>);
};

