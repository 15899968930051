import { useEffect,useState,useContext } from 'react';
import { useAuth } from 'react-oidc-context';
import AppContext from '../../../AppContext';
import {uscall,getresourceurl/*,getfirst*/,usurl} from '../../../us';
import DiscoverBox from './Discover';

function ContinueBox({lastProduct,/*lastBuy,*/professionecode,producttypes}) {
    const auth = useAuth();
    const appcontext = useContext(AppContext);
    //const [lastBuy,setLastBuy]=useState(null);
    //const [lastBuyproduct,setLastBuyproduct]=useState(null);
    //const [lastProduct,setLastProduct]=useState(null);
    const [productimageurl,setProductimageurl]=useState(null);
    const [producttype,setProducttype]=useState(null);
    const [productviewurl,setProductviewurl]=useState(null);
    const [productstat,setProductstat]=useState(null);
    const [productstarted,setProductstarted]=useState(true);
    /*useEffect(() => {
	const cb=async function(){
		const r=await uscall(appcontext,auth,'ecommerce',null,'order_getlastuserorder',null,null);
//console.log('lastorder:'+JSON.stringify(r));
		if(typeof(r['error'])!='undefined');
		else if(typeof(r['result'])!='undefined' && r.result)setLastBuy(r.result);
	}
	if(lastBuy);else 
		cb();
    },[auth,appcontext,lastBuy]);*/
    /*useEffect(() => {
	const cb=async function(){
		if(lastBuy && typeof(lastBuy['items'])!='undefined'){
			for(let k=0;k<lastBuy.items.length;k++){
				setLastBuyproduct(lastBuy.items[k]);
				break;
			}
		}
	}

	if(lastBuy){
		if(lastBuyproduct);else 
			cb();
	}
    },[auth,appcontext,lastBuy,lastBuyproduct]);
    useEffect(() => {
	const cb=async function(){
		const r=await uscall(appcontext,auth,'ecommerce',null,'product_search',null,{filter:{field:'isbn',func:'eq',value:lastBuyproduct.product.magento_custom.isbn},limit:1});
		const first=getfirst(r);
		if(first){
			setLastProduct(first);
		}
	}

	if(lastBuyproduct){
		if(lastProduct);else 
			cb();
	}
    },[auth,appcontext,lastBuyproduct,lastProduct]);*/
    useEffect(() => {
	const cb=async function(){
		try{
		if(lastProduct.copertina){
			setProductimageurl(await getresourceurl(appcontext,auth,lastProduct.copertina));
		}else if(lastProduct.magento_custom && lastProduct.magento_custom.image){
			setProductimageurl(lastProduct.magento_custom.image);
		}
		}catch(err){}
	}
	if(lastProduct){
		//if(productimageurl);else 
			cb();
	}
    },[auth,appcontext,lastProduct]);
    useEffect(() => {
	const cb=async function(){
		//setProducttype(getfirst(await uscall(appcontext,auth,'pim','producttype','load',null,[lastProduct.type])));
				if(typeof(producttypes[lastProduct.type])!=='undefined'){
					setProducttype(producttypes[lastProduct.type]);
					return;
				}
				for(let k in producttypes){
					if(producttypes[k].code===lastProduct.type){
						setProducttype(producttypes[k]);
						break;
					}
				}

	}
	if(lastProduct){
		if(producttype);else if(producttypes)
			cb();
	}
    },[auth,appcontext,lastProduct,producttype,producttypes]);
    useEffect(() => {
	const cb=async function(){
		if(producttype.code==='ebook' || producttype.code=='paper_flex' || producttype.code=='paper_hard')
			setProductviewurl(await usurl(appcontext,auth,'reader','userproductsession','read',lastProduct.uuid+'/'));
	}
	if(lastProduct && producttype){
		if(productviewurl);else 
			cb();
	}
    },[auth,appcontext,lastProduct,producttype,productviewurl]);
    useEffect(() => {
	const cb=async function(){
		//if(producttype.code==='ebook'){
			const r=(await uscall(appcontext,auth,'reader','userproductsession','status',[lastProduct.uuid]));
			if(r && typeof(r['result'])!='undefined' && typeof(r['result']['status'])!='undefined')
				setProductstat(r.result.status);
			else
				setProductstat('');
			if(r && typeof(r['result'])!='undefined' && typeof(r['result']['startedreading'])!='undefined')
				setProductstarted(r['result']['startedreading']);
		//}
	}
	if(lastProduct && lastProduct.uuid && producttype){
		if(productstat!==null);else 
			cb();
	}
    },[auth,appcontext,lastProduct,producttype,productviewurl,productstat]);

//console.log('r');console.log(lastProduct);

    return (
        <>{lastProduct?(
                <div className='dash-box h-460 continue-box'>
                    <h1 className='mb-0'>Ciao {auth.user?.profile.given_name}, continua la tua formazione</h1>
                    <div className="row g-0 mb-1">
                        <div className="col-md-auto img-cover">
                            <img alt="continua" src={productimageurl?productimageurl:`${process.env.PUBLIC_URL}/placeholder.png`} />
							{/*TODO<button className="chip"><span>Libro</span></button>*/}
                        </div>
                        <div className="col-md ps-md-4 space-between">
                            <div className={lastProduct?'':'placeholder-glow'}>
                                <h2 className={lastProduct?'':'placeholder'}>{lastProduct?(lastProduct.name?lastProduct.name:(lastProduct.title?lastProduct.title:(lastProduct.magento_custom?lastProduct.magento_custom.title:''))):''}</h2>
                                <div className="tags">
					{lastProduct && lastProduct.uuid && (producttype==='ebook' || producttype==='paper_flex' || producttype==='paper_hard')?(<>
                                    <button className="tag addons"><span>Contenuti aggiuntivi</span></button>
                                    <button className="tag audio"></button>
                                    <button className="tag bookmark"></button>
					</>):(<></>)}
                                </div>
                            </div>
			{producttype?(<>
				{productviewurl?(
                            <div className='mb-1'>
				{productstat?(<>
                                <p>Continua da dove avevi lasciato</p>
                                <h3 alt={productstat.name}>{productstat.name}</h3>
				</>):(<></>)}
                                <a href={productviewurl} className="primary">{productstarted?(<>Continua a leggere</>):(<>Inizia a leggere</>)}</a>
                            </div>
				):(<></>)}
			</>):(<></>)}
                        </div>
                    </div>
                </div>):(<DiscoverBox areacode={professionecode?(professionecode==='student'?'dashboard_studenti':(professionecode==='professor'?'dashboard_docenti':'dashboard_professionisti')):null} />)}
        </>
    )
}

export default ContinueBox;
