import { useParams } from 'react-router-dom';
import './landing.css';
import data from './banner-boxes.json'

function BoxLanding() {
    const { landingPage } = useParams();
    const content = data[landingPage] || [];

    return (
        <div className="d-flex banner-boxes">
            {content.map((item, index) => (
                <div key={index}>
                    <span className="box-tag">{item.tag}</span>
                    <h3>{item.title}</h3>
                    <p>{item.text}</p>
                    <hr />
                    <button className="gradient-button">
                        {item.buttonText} 
                        <img src={`${process.env.PUBLIC_URL}/${item.buttonIcon}`} alt={item.buttonText} />
                    </button>
                </div>
            ))}
        </div>
    );
}

export default BoxLanding;
