import './password.css';
import AppContext from '../../AppContext';
import { useAuth } from 'react-oidc-context';
import { useNavigate,useParams } from 'react-router-dom';
import { useEffect,useState,useContext } from 'react';
import { ToastContainer } from 'react-toastify';
import { notifyWarn} from '../utilities/toaster';
import { Link } from 'react-router-dom';
import {uscall} from '../../us';

function ForgotPasswordReset () {
    const [isValid, setIsValid] = useState(false);
    const [password, setPassword] = useState('');
    const [isSubmitted, setIsSubmitted] = useState(false);// used to raise warnings
    const [isSubmitting, setIsSubmitting] = useState(false);// used to avoid double submissions
    const {token}=useParams();

    const auth = useAuth();
    const appcontext = useContext(AppContext);
    let navigate = useNavigate();
    //useEffect(() => {
    //  if(auth.isAuthenticated){
    //    navigate('/');
    //  }
    //},[auth,navigate]);

    useEffect(function(){
	const cb=async function(){
		const r=await uscall(appcontext,auth,'aaa','passwordreset','verify',null,{token:token});
		if(typeof(r['error'])!='undefined');
		else if(typeof(r['result'])!='undefined' && r.result)setIsValid(true);
	}
	cb();
    },[token,auth,appcontext]);


    const checkPassword=function(v){
	return (v.trim().length>0 && v.trim().match(/^.*(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!#$%&@?-]).*$/))
	// https://stackoverflow.com/questions/2370015/regular-expression-for-password-validation
	// (?=.{8,})        : Length
	// (?=.*[a-zA-Z])   : Letters
	// (?=.*\d)         : Digits
	// (?=.*[!#$%&?"]) : Special characters
    };

    const doRecover=function(){
	return new Promise(async function(resolve,reject){
		try{
			let c=await fetch('/api/passwordreset/reset/',{method:'POST',headers:{'Content-Type':'application/json'},body:JSON.stringify({
				token:token,
				password:password,
			})});
			let r=await c.json();
			if(typeof(r['error'])!='undefined')resolve(null);
			else if(typeof(r['result'])!='undefined' && r.result);
			else resolve(null);

			resolve(r.result);
		}catch(err){
			reject(err);
		}
	});
    }
    const handleSubmit = (e) => {
        e.preventDefault();
	if(isSubmitting)return;

        setIsSubmitted(true);
	if(checkPassword(password));else return;
        doRecover().then(function(isok){
        	setIsSubmitting(false);
		if(isok){
        		setIsSubmitted(false);
			navigate('/forgot-password/password-updated/');
		}else{
        		setIsSubmitted(false);
            		notifyWarn(
            		    process.env.PUBLIC_URL+'alert.svg',
            		    'Password non impostata',
            		    <>La sessione è scaduta, prova a richiedere di nuovo il cambio della password</>,
            		);
		}
	},function(err){
        	setIsSubmitting(false);
        	setIsSubmitted(false);
            	notifyWarn(
            	    process.env.PUBLIC_URL+'alert.svg',
            	    'Errore di comunicazione',
            	    <>Ti preghiamo di riprovare più tardi</>,
            	);
	});
    };

    return( <>{auth.isAuthenticated?(<></>):(<>{isValid?(

        <div className='page w-100'>
            <div className='row h-100 g-0'>
                <div className='col-6 page-background'></div>
                <div className='col-6 page-right'>
                    <h1>Password dimenticata</h1>
                    <p>Non preoccuparti, imposta la tua nuova password.<br/>
                    Inserisci la tua nuova password
                    </p>
                    <p>Se non riesci a resettare la password associata all'account Giappichelli potrai<br/>
                    contattare il Servizio Clienti per ricevere assistenza sul ripristino<br/>
                    dell'accesso all'account.</p>
                    <form onSubmit={handleSubmit}>
                        <div>
                            <label htmlFor="password" className='form-label'>Password *</label>
                            <input
                                type='password'
                                id="password"
                                placeholder='Password'
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required={isSubmitted && !checkPassword(password)}
				pattern="^.*(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!#$%&@?\-]).*$"
                            />
                        </div>
                        <div>
                            <h4>La password deve comprendere:</h4>
                            <ul>
                                <li>8 caratteri o più</li>
                                <li>almeno un carattere speciale (# ! ? -)</li>
                                <li>almeno 1 lettera maiuscola</li>
                                <li>almeno 1 numero</li>
                            </ul>
                        </div>
                        <div>
                            <p>Non hai ricevuto la mail? <Link to="/forgot-password/">Rinvia</Link></p>
                        </div>
                        <div>
                            <button className='gradient-button' type="submit">Invia</button>
                        </div>
                    </form>
                    <ToastContainer className="custom-toast custom-toast-warning" />
                </div>
            </div>
        </div>
	):(
        <div className='page w-100'>
            <div className='row h-100 g-0'>
                <div className='col-6 page-background'></div>
                <div className='col-6 page-right'>
                    <h1>Password dimenticata</h1>
                    <p>Ooops. il tuo link è scaduto!  <Link to="/forgot-password/">Richiedine un altro</Link></p>
                </div>
            </div>
        </div>
	)}</>
    )}</>)

}

export default ForgotPasswordReset;
