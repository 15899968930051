
import {formatprice} from '../../us';

const CheckoutOptions=function({orderinfo,updateOrderData}){
	return (<></>);// disabled options
	return (<>
					{orderinfo.has_option_advance?(<>
                                        <h2>Opzioni di acquisto</h2>
                                        <>
                                            <div className='row purchase-options'>
                                                <div className='col-6 checkoption'>
                                                    <div className='row align-items-center'>
                                                        <div className='col-auto'>
                                                            <input className={orderinfo.option_advance?'selected':''} type='radio' name="option_advance" value='1' checked={orderinfo.option_advance} onClick={updateOrderData} />
                                                        </div>
                                                        <div className='col'>
                                                            <h3>{formatprice(orderinfo.shown_total_option_advance_true)}</h3>
                                                            <p>Paga in un'unica soluzione per un abbonamento di 6 mesi</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-6 checkoption'>
                                                    <div className='row align-items-center'>
                                                        <div className='col-auto'>
                                                            <input className={orderinfo.option_advance?'':'selected'} type='radio' name="option_advance" value='0' checked={!orderinfo.option_advance} onClick={updateOrderData} />
                                                        </div>
                                                        <div className='col'>
                                                            <h3>{formatprice(orderinfo.shown_total_option_advance_false)}/mese</h3>
                                                            <p>Costo mensile in abbonamento per un periodo di 6 mesi</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> 
                                        </></>):(<></>)}
</>);

};
export default CheckoutOptions;
