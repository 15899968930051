import './register.css';
import { Link } from 'react-router-dom';
import { useAuth } from 'react-oidc-context';
import { useNavigate } from 'react-router-dom';
import { useEffect,useState,useContext } from 'react';
import { notifyWarn } from '../utilities/toaster';
import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import AppContext from '../../AppContext';
import {uscall} from '../../us';


const RegisterComponent = () => {
    const [email, setEmail] = useState('');
    const [nome, setNome] = useState('');
    const [cognome, setCognome] = useState('');
    const [password, setPassword] = useState('');
    const [privacyChecked, setPrivacyChecked] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);// used to raise warnings
    const [isSubmitting, setIsSubmitting] = useState(false);// used to avoid double submissions

    const appcontext = useContext(AppContext);
    const auth = useAuth();
    let navigate = useNavigate();
    useEffect(() => {
      if(auth.isAuthenticated){
        navigate('/');
      }
    },[auth,navigate]);

    const [googleLogin,setGoogleLogin] = useState(null);
    useEffect(function(){
	const cb=async function(){
	      if(!googleLogin){
    			setGoogleLogin(await uscall(appcontext,auth,'aaa','session','google-loginlink',null,{url:document.location.origin+'/api/session/google-callback/?client_id='+encodeURIComponent(appcontext.client_id)+'&redirect_uri='+encodeURIComponent(document.location.origin+'/api/session/authorize/')}));
	      }
	};
	cb();
    },[googleLogin,appcontext.client_id,appcontext,auth/*,setGoogleLogin*/]);// run Once
    const doGoogleLogin=function(){
    	if(googleLogin && googleLogin.url)auth.signinRedirect({login_hint:'google'});//document.location.href=googleLogin.url;
    };


    const checkName=function(v){
	return (v.trim().length>0)
    };
    const checkMail=function(v){
	return (v.trim().length>0 && v.trim().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/));
	// https://stackoverflow.com/questions/46155/how-can-i-validate-an-email-address-in-javascript
    };
    const checkPassword=function(v){
	return (v.trim().length>0 && v.trim().match(/^.*(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!#$%&@?-]).*$/))
	// https://stackoverflow.com/questions/2370015/regular-expression-for-password-validation
	// (?=.{8,})        : Length
	// (?=.*[a-zA-Z])   : Letters
	// (?=.*\d)         : Digits
	// (?=.*[!#$%&?"]) : Special characters
    };


    const doRegister=function(){
	return new Promise(async function(resolve,reject){
		try{
			let c=await fetch('/api/user/register/',{method:'POST',headers:{'Content-Type':'application/json'},body:JSON.stringify({
				username:email,
				password:password,
				firstname:nome,
				lastname:cognome,

				client_id:appcontext.client_id,
				// avoid having to dug into oidc internals
				//code_challenge:
				//code_challenge_method:'S256',
				//nonce:
				//state:
				//scope:
			})});
			let r=await c.json();
			if(typeof(r['error'])!='undefined')resolve(null);
			else if(typeof(r['result'])!='undefined' && r.result);
			else resolve(null);

			resolve(r.result);
		}catch(err){
			reject(err);
		}
	});
    };
    const doAutoLogin=function(r){// cookies are set, user should be auto-authenticated
        auth.signinRedirect();
    }

    const handleSubmit = (e) => {
        e.preventDefault();
	    if(isSubmitting)return;

        setIsSubmitted(true);
	    if(checkName(nome) && checkName(cognome) && checkMail(email) && checkPassword(password) && privacyChecked);else return;

        setIsSubmitting(true);
        doRegister().then(function(isok){
        	setIsSubmitting(false);
		    if(isok){
			    doAutoLogin(isok);
		    }else{
        		setIsSubmitted(false);
            		notifyWarn(
            		    process.env.PUBLIC_URL+'alert.svg',
            		    'Email già registrata',
            		    <>L'email con cui stai cercando di registrati è gia presente nel nostro database.<br />Vai alla pagina login per accedere.</>,
            		    <Link to="/login/">Pagina Login</Link>
            		);
		}
	    },function(err){
        	setIsSubmitting(false);
        	setIsSubmitted(false);
            	notifyWarn(
            	    process.env.PUBLIC_URL+'alert.svg',
            	    'Errore di comunicazione',
            	    <>Ti preghiamo di riprovare più tardi</>,
            	);
	    });
    };


    return( <>{auth.isAuthenticated?(<></>):(

        <div className='page w-100'>
            <div className='row h-100 g-0'>
                <div className='col-6 page-background'></div>
                <div className='col-6 page-right'>
                    <h1>Crea il tuo account</h1>
                    <p>Crea il tuo account Giappichelli! Se possiedi già un account <Link to="/login/">accedi</Link> subito.</p>
		{googleLogin && googleLogin.url ? (<>
                    <div className='google-access' onClick={doGoogleLogin}>
                        <img src={`${process.env.PUBLIC_URL}/google-access.svg`} alt="Accedi con Google" />
                        <span>Accedi con Google</span>
                    </div>
                    <div className='divider'><h3>Oppure</h3></div>
		</>):(<></>)}
                    <form onSubmit={handleSubmit}>
                        <div className='row'>
                            <div className='col-6'>
                                <label htmlFor="nome" className='form-label'>Nome *</label>
                                <input
                                    type='text'
                                    id="nome"
                                    placeholder='Nome'
                                    value={nome}
                                    onChange={(e) => setNome(e.target.value)}
                                    required={isSubmitted && !checkName(nome)}
                                />
                            </div>
                            <div className='col-6'>
                                <label htmlFor="cognome" className='form-label'>Cognome *</label>
                                <input
                                    type='text'
                                    id="cognome"
                                    placeholder='Cognome'
                                    value={cognome}
                                    onChange={(e) => setCognome(e.target.value)}
                                    required={isSubmitted && !checkName(cognome)}
                                />
                            </div>
                        </div>
                        <div>
                            <label htmlFor="email" className='form-label'>Email *</label>
                            <input
                                type='email'
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                id="email"
                                placeholder='Email'
                                required={isSubmitted && !checkMail(email)}
                            />
                        </div>
                        <div>
                            <label htmlFor="password" className='form-label'>Password *</label>
                            <input
                                type='password'
                                id="password"
                                placeholder='Password'
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required={isSubmitted && !checkPassword(password)}
				pattern="^.*(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!#$%&@?\-]).*$"
                            />
                        </div>
                        <div>
                            <h4>La password deve comprendere:</h4>
                            <ul>
                                <li>8 caratteri o più</li>
                                <li>almeno un carattere speciale (# ! ? -)</li>
                                <li>almeno 1 lettera maiuscola</li>
                                <li>almeno 1 numero</li>
                            </ul>
                        </div>
                        <div>
                            <button className='gradient-button' type="submit">Crea account</button>
                        </div>
                        <div className='row'>
                            <div className='col-auto'>
                                <input 
                                    type='checkbox' 
                                    checked={privacyChecked} 
                                    onChange={(e) => setPrivacyChecked(e.target.checked)} 
                                    required={isSubmitted && !privacyChecked}
                                />
                            </div>
                            <div className='col'>
                                <h5>Continuando accetti le condizioni d'uso e vendita di G. Giappichelli Editore. Consulta la nostra <a href={`${process.env.REACT_APP_PREFIX_MAGENTO}/privacy`}>informativa sulla privacy</a> e sui <a href={`${process.env.REACT_APP_PREFIX_MAGENTO}/condizioni-di-vendita`}>termini d'uso</a> della piattaforma.</h5>
                            </div>
                        </div>
                    </form>
                    <ToastContainer className="custom-toast custom-toast-warning" />
                </div>
            </div>
        </div>

    )}</>)

}

export default RegisterComponent;
