import {useState,useEffect} from 'react';
import {formatprice} from '../../us';

const CheckoutPaymentSepa=function({orderinfo,myhubcode,paymenthubs,paymenthub,setCurPaymentHub}){
	const [isenabled,setIsEnabled]=useState(null);

	useEffect(()=>{
		const cb=function(){
			/*let found=false;
			for(let k in orderinfo.paymenthubs){
				for(let h in paymenthubs){
					if(paymenthubs[h].uuid==orderinfo.paymenthubs[k]){
						if(paymenthubs[h].code==myhubcode)found=true;
						break;
					}
				}
			}*/
			setIsEnabled(typeof(paymenthubs[myhubcode])!='undefined');
		};
		if(isenabled===null && orderinfo && paymenthubs)cb();
	},[isenabled,orderinfo,paymenthubs,myhubcode]);

	return (<>
			{isenabled?(<>

                                    <div className='row align-items-center pt-4'>
                                        <div className='col-auto'>
                                            <input name='paymenthub' className='d-flex' type="radio" value={myhubcode} onClick={setCurPaymentHub} checked={myhubcode===paymenthub} />
                                        </div>
                                        <div className='col'>
                                            <h5 className='m-0'>Bonifico bancario</h5>
                                        </div>
                                        <div className='col-auto'>
                                            <img src={`${process.env.PUBLIC_URL}/bank.svg`} alt="Bonifico bancario" />
                                        </div>
                                    </div>
                                    <div className='divider-row'></div>
				<>{myhubcode===paymenthub?(<>
                                    <div className='row text-block'>
                                        <div className='col'>
                                            <div>
                                                <h4>Informazioni per effettuare il Bonifico Bancario</h4>
                                            </div>
                                            <div>
                                                <p>Iban</p>
                                                <h5>IT798237492749297992834928293</h5>
                                            </div>
                                            <div>
                                                <p>Intestatario conto corrente</p>
                                                <h5>Giappichelli Spa</h5>
                                            </div>
                                            <div>
                                                <p>Banca</p>
                                                <h5>Credit Agricole</h5>
                                            </div>
                                            <div>
                                                <p>Importo</p>
                            			<h5>{orderinfo.has_option_advance?(<>
							{orderinfo.option_advance?(<>
								{formatprice(orderinfo.shown_total_option_advance_true+orderinfo.shipment_price)}
								</>):(<>
								{formatprice(orderinfo.shown_total_option_advance_false+orderinfo.shipment_price)}
							</>)}
							</>):(<>
							{formatprice(orderinfo.shown_total_option_advance_true+orderinfo.shipment_price)}
							</>)}</h5>
                                            </div>
                                            <div>
                                                <p>Casuale Bonifico</p>
                                                <h5>Pagamento ordine n° {orderinfo.ordercode}</h5>
                                            </div>
                                            <div className='m-0'>
                                                <p>N.B. una volta effettuato il bonifico invia la copia contabile all’indirizzo email ordini_bonifici@giappichelli.it</p>
                                            </div>
                                        </div>
                                        <div className='col-auto'></div>

                                    </div>
                                    <div className='divider-row'></div>
				</>):(<></>)}</>
			</>):(<></>)}
</>);
};
export default CheckoutPaymentSepa;
