import {useState,useEffect} from 'react';
//import {formatprice} from '../../us';

const CheckoutPaymentCartaStudente=function({orderinfo,myhubcode,paymenthubs,paymenthub,setCurPaymentHub}){
	const [isenabled,setIsEnabled]=useState(null);

	useEffect(()=>{
		const cb=function(){
			/*let found=false;
			for(let k in orderinfo.paymenthubs){
				for(let h in paymenthubs){
					if(paymenthubs[h].uuid==orderinfo.paymenthubs[k]){
						if(paymenthubs[h].code==myhubcode)found=true;
						break;
					}
				}
			}*/
			setIsEnabled(typeof(paymenthubs[myhubcode])!='undefined');
		};
		if(isenabled===null && orderinfo && paymenthubs)cb();
	},[isenabled,orderinfo,paymenthubs,myhubcode]);

	return (<>
			{isenabled?(<>

                                    <div className='row align-items-center pt-4'>
                                        <div className='col-auto'>
                                            <input name='paymenthub' className='d-flex' type="radio" value={myhubcode} onClick={setCurPaymentHub} checked={myhubcode===paymenthub} />
                                        </div>
                                        <div className='col'>
                                            <h5 className='m-0'>Carta dei servizi</h5>
                                        </div>
                                        <div className='col-auto'>
                                            <img src={`${process.env.PUBLIC_URL}/creditcard.svg`} alt="Carta servizi" />
                                        </div>
                                    </div>
                                    <div className='divider-row'></div>
				<>{myhubcode===paymenthub?(<>
                                    <div className='row'>
                                        <div className='col'>
                                            <label htmlFor="servicecard">Codice carta dei servizi</label>
                                            <input name='servicecard' id="servicecard" type='text' placeholder='Inserisci il codice carta' />
                                        </div>
                                        <div className='col-auto align-content-end'>
                                            <button><span>Convalida codice</span></button>
                                        </div>
                                    </div>
                                    <div className='divider-row'></div>
				</>):(<></>)}</>
			</>):(<></>)}
</>);
};
export default CheckoutPaymentCartaStudente;
