
const CheckoutShipment=function({orderinfo,updateOrderData}){
	return (<>
					{orderinfo.hasshipment?(<>
                                        <h2>Indirizzo di spedizione</h2>
                                        <>
                                            <div className='row'>
                                                <div className='col-6'>
                                                    <label htmlFor='shipment_firstname'>Nome *</label>
                                                    <input type='text' name='shipment_firstname' value={orderinfo.shipment_firstname} onChange={updateOrderData} required />
                                                </div>
                                                <div className='col-6'>
                                                    <label htmlFor='shipment_lastname'>Cognome *</label>
                                                    <input type='text' name='shipment_lastname' value={orderinfo.shipment_lastname} onChange={updateOrderData} required />
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <label htmlFor='shipment_address'>Indirizzo *</label>
                                                    <input className='w-100' type='text' name='shipment_address' value={orderinfo.shipment_address} onChange={updateOrderData} required />
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-6'>
                                                    <label htmlFor='shipment_at'>Presso</label>
                                                    <input type='text'  name='shipment_at' value={orderinfo.shipment_at} onChange={updateOrderData}/>
                                                </div>
                                                <div className='col-6'>
                                                    <label htmlFor='shipment_mobile'>Cellulare</label>
                                                    <input type='phone'  name='shipment_mobile' value={orderinfo.shipment_mobile} onChange={updateOrderData}/>
                                                </div>
                                            </div>
                                            <div className='row pt-3'>
                                                <div className='col'></div>
                                                <div className='col-auto'>
                                                    <div className='save-address'>
                                                        <input type='checkbox'  name='shipment_save' checked={orderinfo.shipment_save?'checked':''} onChange={updateOrderData}/>
                                                        <p>Salva indirizzo per il prossimo ordine</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </></>):(<></>)}
</>);

};
export default CheckoutShipment;
