import { useEffect,useState,useContext } from 'react';
import AppContext from '../../../AppContext';
import { useAuth } from 'react-oidc-context';
import {uscall} from '../../../us';

function RequestCopyBox({areacode}) {
    const auth = useAuth();
    const [box,setBox]=useState(null);
    const appcontext = useContext(AppContext);
    useEffect(() => {
	const cb=async function(){
		const r=await uscall(appcontext,auth,'ecommerce','boxcontactarea','getbox',null,{areacode:areacode});
		if(typeof(r['error'])!='undefined');
		else if(typeof(r['result'])!='undefined' && r.result)setBox(r.result);
	}
	if(box);else 
		cb();
    },[box,areacode,auth,appcontext,appcontext.manifest.relations]);

    return (
        <>{box && (
            <div className='col-md mb-4 mb-md-0'>
                <div className='dash-box h-230'>
                    <h1 className="p-0 m-0">{box.title}</h1>
                    <p>{box.description}</p>
                    <hr />
                    <a href={box.url} className="tag goto"><span>{box.cta}</span></a>
                </div>
            </div>)}
        </>
    )
}

export default RequestCopyBox;
