import './landing.css';

function NewsLanding() {
   
    return (

        <section className='content-landing'>

        { /* generare dinamicamente h3 e h4 e la classe grid-2 / grid-3 / grid-4 in base al tipo di landing e di contenuto */ }

            <h1>Prodotti Editoriali al servizio di studenti, docenti e professionisti</h1>
            <h4>Un ambiente digitale di risorse didattiche innovative, formazione continua e molto altro per studiare, insegnare e specializzarsi.</h4>
            <div className="d-flex landing-news row g-0 grid-3">
                <div className="col">
                    <div className="news-image">
                        <img src={`${process.env.PUBLIC_URL}/news-img.jpeg`} alt="News" />
                    </div>
                    <div className='card-content'>
                        <h5>eReader</h5>
                        <p>I libri Giappichelli in formato eBook facilmente consultabili con la tecnologia eReader.</p>
                        <hr />
                        <a href='#'>Sfoglia il catalogo</a>
                    </div>
                </div>
                <div className="col">
                    <div className="news-image">
                        <img src={`${process.env.PUBLIC_URL}/news-img.jpeg`} alt="News" />
                    </div>
                    <div className='card-content'>
                        <h5>eReader</h5>
                        <p>I libri Giappichelli in formato eBook facilmente consultabili con la tecnologia eReader.</p>
                        <hr />
                        <a>Sfoglia il catalogo</a>
                    </div>
                </div>
                <div className="col">
                    <div className="news-image">
                        <img src={`${process.env.PUBLIC_URL}/news-img.jpeg`} alt="News" />
                    </div>
                    <div className='card-content'>
                        <h5>eReader</h5>
                        <p>I libri Giappichelli in formato eBook facilmente consultabili con la tecnologia eReader.</p>
                        <hr />
                        <a>Sfoglia il catalogo</a>
                    </div>
                </div>
                <div className="col">
                    <div className="news-image">
                        <img src={`${process.env.PUBLIC_URL}/news-img.jpeg`} alt="News" />
                    </div>
                    <div className='card-content'>
                        <h5>eReader</h5>
                        <p>I libri Giappichelli in formato eBook facilmente consultabili con la tecnologia eReader.</p>
                        <hr />
                        <a>Sfoglia il catalogo</a>
                    </div>
                </div>
                <div className="col">
                    <div className="news-image">
                        <img src={`${process.env.PUBLIC_URL}/news-img.jpeg`} alt="News" />
                    </div>
                    <div className='card-content'>
                        <h5>eReader</h5>
                        <p>I libri Giappichelli in formato eBook facilmente consultabili con la tecnologia eReader.</p>
                        <hr />
                        <a>Sfoglia il catalogo</a>
                    </div>
                </div>
                <div className="col">
                    <div className="news-image">
                        <img src={`${process.env.PUBLIC_URL}/news-img.jpeg`} alt="News" />
                    </div>
                    <div className='card-content'>
                        <h5>eReader</h5>
                        <p>I libri Giappichelli in formato eBook facilmente consultabili con la tecnologia eReader.</p>
                        <hr />
                        <a>Sfoglia il catalogo</a>
                    </div>
                </div>
            </div>
        </section>

    )
}

export default NewsLanding;
