import { useParams,useNavigate/*,useLocation*/,Link } from 'react-router-dom';
import {loadStripe} from '@stripe/stripe-js';
//import StripeCheckoutForm from './StripeCheckoutForm';
import CheckoutContact from './CheckoutContact';
import CheckoutOptions from './CheckoutOptions';
import CheckoutShipment from './CheckoutShipment';
import CheckoutPaymentStripe from './CheckoutPaymentStripe';
import CheckoutPaymentSepa from './CheckoutPaymentSepa';
import CheckoutPaymentScalaPay from './CheckoutPaymentScalaPay';
import CheckoutPaymentCartaStudente from './CheckoutPaymentCartaStudente';
import CheckoutBilling from './CheckoutBilling';
import CheckoutSidebar from './CheckoutSidebar';
import { useContext,useEffect,useState} from 'react';
import PrevPage from "../utilities/prevPage";
//import queryString from 'query-string';
import './checkout.css';

import { useAuth } from 'react-oidc-context';
import AppContext from '../../AppContext';
import UserContext from '../../UserContext';
import {uscall,getfirst/*,formatprice*/} from '../../us';
import { notifyWarn/*,notifySuccess*/} from '../utilities/toaster';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
let stripePromises = {};//loadStripe('pk_test_XUIpXpyaGuuw0Dc9Ng80xFWs');

const Checkout=function(){
	const [orderinfo,setOrderInfo]=useState(null);
	const [orderitems,setOrderItems]=useState(null);
	const [producttypes,setProductTypes]=useState(null);
	const [orderstatuscode,setOrderStatusCode]=useState(null);
	const [paymenthubs,setPaymentHubs]=useState(null);
	const [paymenthub,setPaymentHub]=useState(null);
	const [paymenthubsprops,setPaymentHubsProps]=useState(null);
	const [loadedPaymentLib,setLoadedPaymentLib]=useState(false);
	const [stripeOptions,setStripeOptions]=useState(null);
	const [isSubmitting,setIsSubmitting] = useState(null);
	const {order}=useParams();
	const [stripeElementsO,setStripeElementsO]=useState(null);
	
	//const location = useLocation();
	const navigate=useNavigate();
	//const { setup_intent, setup_intent_client_secret} = queryString.parse(location.search);

	const auth = useAuth();
	const appcontext = useContext(AppContext);
	const usercontext = useContext(UserContext);

	useEffect(() => {
		if(auth.isAuthenticated);
		else 
			navigate('/login/?redirect='+encodeURIComponent(document.location.href));
	},[auth,navigate]);

	const loadOrderInfo=async function(){
			setOrderStatusCode(null);
			setPaymentHubs(null);
			setPaymentHub(null);
			setPaymentHubsProps(null);
			setLoadedPaymentLib(false);
			setStripeOptions(null);
			setStripeElementsO(null);
			const orderinfo=getfirst(await uscall(appcontext,auth,'order','order','load',null,[order]));
			if(orderinfo.customer===usercontext.user.uuid){
				setOrderInfo(orderinfo);
			}else{
				navigate('/');
			}
	};

	useEffect(()=>{
		if(usercontext && (!orderinfo))loadOrderInfo();
	},[order,orderinfo,appcontext,auth,navigate,usercontext/*,loadOrderInfo*/]);

	useEffect(()=>{
		const cb=async function(){
			const orderstatus=getfirst(await uscall(appcontext,auth,'order','orderstatus','load',null,[orderinfo.status]))
			setOrderStatusCode(orderstatus.code);
		};
		if(orderinfo && (!orderstatuscode))cb();
	},[orderinfo,orderstatuscode,appcontext,auth,order]);

	useEffect(()=>{
		const cb=async function(){
			const orderitems=(await uscall(appcontext,auth,'order','orderitem','load',null,orderinfo.items));
			setOrderItems(orderitems.result);
		};
		if(orderinfo && (!orderitems))cb();
	},[orderinfo,orderitems,appcontext,auth,order]);
	useEffect(()=>{
		const cb=async function(){
			let pts=[];
			for(let i in orderitems){
				if(orderitems[i].producttype && (!pts.includes(orderitems[i].producttype)))pts.push(orderitems[i].producttype);
			}
			const producttypes=(await uscall(appcontext,auth,'pim','producttype','load',null,pts))
			setProductTypes(producttypes.result);
		};
		if(orderitems && (!producttypes))cb();
	},[orderitems,producttypes,appcontext,auth,order]);

	useEffect(()=>{
		const cb=async function(){
			const orderpaymentinfo=(await uscall(appcontext,auth,'order','order','order_getpaymenthubs',encodeURIComponent(order)+'/'));
			if(orderpaymentinfo && orderpaymentinfo.result){
				for(let k in orderpaymentinfo.result){
					if(orderpaymentinfo.result[k].hub==='stripe'){
						stripePromises[k]=loadStripe(orderpaymentinfo.result[k].publickey);
					}
				}
				setPaymentHubs(orderpaymentinfo.result);
			}
			setLoadedPaymentLib(true);
		};
		if(orderinfo && (!loadedPaymentLib) && orderstatuscode /*&&  orderstatuscode==='new'*/)cb();
	},[orderinfo,loadedPaymentLib,appcontext,auth,order,orderstatuscode]);


	useEffect(()=>{
		const cb=async function(){
			//if(orderinfo.status==='waitingforpayment');else return;//THIS DECIDES THE ROUTING!
			// retrieve stripe customer id and setStripeCustomer
			if((!orderinfo.paymenthub) && orderstatuscode==='new'){
				let props={};
				let cur=null;
				if(orderinfo.paymenthub){
					const p=(await uscall(appcontext,auth,'order','order','order_getpaymenthub',encodeURIComponent(order)+'/'+encodeURIComponent(orderinfo.paymenthub)+'/'));
					if(p && p.result){
						props[p.result.hubcode]=p.result;
						cur=p.result.hubcode;
					}
				}else{
					for(let k in paymenthubs){
						const p=(await uscall(appcontext,auth,'order','order','order_getpaymenthub',encodeURIComponent(order)+'/'+encodeURIComponent(k)+'/'));
						if(p && p.result){
							props[k]=p.result;
							if(!cur)cur=k;
						}
					}
				}
				setPaymentHubsProps(props);
				setPaymentHub(cur);
			}else if(orderinfo.paymenthub)
				setPaymentHub(paymenthubs[orderinfo.paymenthub].code);
		}
		if(orderinfo && loadedPaymentLib && paymenthubs &&  (!paymenthubsprops) && orderstatuscode)cb();
	},[orderinfo,loadedPaymentLib,paymenthubs,paymenthubsprops,appcontext,auth,order,orderstatuscode]);


	useEffect(()=>{
		const cb=async function(){
			if(paymenthubs[paymenthub].hub==='stripe'){
				if(paymenthubsprops[paymenthub].method==='stripesetup'){
					setStripeOptions({// https://docs.stripe.com/js/elements_object/create#stripe_elements-options
						customerSessionClientSecret:paymenthubsprops[paymenthub]['paymenthub_'+paymenthub+'_customersession_clientsecret'],
						mode:'setup',
						setupFutureUsage:'off_session',
						currency:'eur',
						locale:'it',
						appearance:{//https://docs.stripe.com/elements/appearance-api/
							theme: 'stripe',
							variables: {
								colorPrimary: 'rgb(37, 37, 37)',//'#0570de',
								colorBackground: '#ffffff',
								colorText: 'rgb(82, 82, 82)',//'#30313d',
								colorDanger: '#df1b41',
								fontFamily: 'Figtree, sans-serif',//'Ideal Sans, system-ui, sans-serif',
								spacingUnit: '4px',
								borderRadius: '4px',
							},
							/*rules: {
								'.Tab[data-testid="saved"]':{
								}
								'.TabLabel[data-selected-payment-form="saved"]':{
								}
							}*/
						}
					});
					//TODO: Address? https://docs.stripe.com/payments/save-during-payment?platform=web&ui=elements&client=react#collect-address
					//TODO: Layout? https://docs.stripe.com/payments/save-during-payment?platform=web&ui=elements&client=react#customize-layout
				}else{//TODO: checkout
				}
			}
		}
		if(orderinfo && loadedPaymentLib && paymenthub && paymenthubs &&  (paymenthubsprops) && (!stripeOptions) && orderstatuscode && orderstatuscode==='new')cb();
	},[orderinfo,loadedPaymentLib,paymenthub,paymenthubs,paymenthubsprops,stripeOptions,orderstatuscode]);

	useEffect(()=>{// disabled options
		if(orderinfo && orderinfo.has_option_advance && orderinfo.option_advance)
			setOrderInfo({...orderinfo,option_advance:false});
	},[orderinfo,setOrderInfo]);

	const updateOrderData=function(event){
		if(orderstatuscode && orderstatuscode==='new');else return;
		let neworderinfo={...orderinfo};
		if(event.target.name==='option_advance'){
			neworderinfo[event.target.name]=(event.target.value==='1'?true:false);
		}else if(event.target.name==='shipment_save'){
			neworderinfo[event.target.name]=(event.target.checked?true:false);
		}else
			neworderinfo[event.target.name]=event.target.value;
		setOrderInfo(neworderinfo);
	};
	const SwapCheckbox=function(event){
		let neworderinfo={...orderinfo};
		neworderinfo[event.target.name]=(event.target.checked?true:false);
		setOrderInfo(neworderinfo);
	}

	useEffect(()=>{
		const cb=async function(){
			if(paymenthubs[paymenthub].hub==='stripe'){
				const mystripe=await stripePromises[paymenthub];
				const setupIntent=await mystripe.retrieveSetupIntent(paymenthubsprops[paymenthub]['paymenthub_'+paymenthub+'_setupintent_clientsecret']);
//console.log('status');console.log(paymenthubsprops[paymenthub]['paymenthub_'+paymenthub+'_setupintent_clientsecret']);console.log(setupIntent.setupIntent.status);
				switch (setupIntent.setupIntent.status) {// https://docs.stripe.com/payments/setupintents/lifecycle
					case 'succeeded':
						if(orderinfo && orderinfo.paymenthub);else{
							(await uscall(appcontext,auth,'order','order','order_getpaymenthub',encodeURIComponent(order)+'/'+encodeURIComponent(paymenthub)+'/'));
						}
						const r=await uscall(appcontext,auth,'order','order','order_checkpaymenthub',encodeURIComponent(order)+'/'+encodeURIComponent(paymenthub)+'/',{});
						if(r && r.result){
							await loadOrderInfo();
							navigate('/checkout/'+encodeURIComponent(order)+'/');
						}else{
							await uscall(appcontext,auth,'order','order','order_resetpaymenthub',encodeURIComponent(order)+'/',{});//rimetto in new
							await loadOrderInfo();
            						notifyWarn(
            						    process.env.PUBLIC_URL+'alert.svg',
            						    'Errore',
            						    'Card Payment Failed'
            						);
						}
						break;
					case 'processing':
						setTimeout(()=>{
							navigate('/checkout/'+encodeURIComponent(order)+'/');
						},1000);
						break;
					case 'requires_payment_method':
							//await uscall(appcontext,auth,'order','order','order_resetpaymenthub',encodeURIComponent(order)+'/',{});//rimetto in new
							//navigate('/checkout/'+encodeURIComponent(order)+'/');
							//await loadOrderInfo();
            						//notifyWarn(
            						//    process.env.PUBLIC_URL+'alert.svg',
            						//    'Errore',
            						//    'Card Payment Failed'
            						//);
						break;
					case 'requires_action':
						break;
					case 'requires_confirmation':
						break;
					case 'canceled':
							await uscall(appcontext,auth,'order','order','order_resetpaymenthub',encodeURIComponent(order)+'/',{});//rimetto in new
							await loadOrderInfo();
            						notifyWarn(
            						    process.env.PUBLIC_URL+'alert.svg',
            						    'Errore',
            						    'Card Payment Cancelled'
            						);
						break;
					default:
							await uscall(appcontext,auth,'order','order','order_resetpaymenthub',encodeURIComponent(order)+'/',{});//rimetto in new
							await loadOrderInfo();
            						notifyWarn(
            						    process.env.PUBLIC_URL+'alert.svg',
            						    'Errore',
            						    'Payment Cancelled'
            						);
						break;
				}
			}
		}
		if((orderstatuscode==='new' || orderstatuscode==='waitforpayment') && paymenthub &&  typeof(stripePromises[paymenthub])!='undefined')cb();
	},[appcontext,auth,navigate,order,orderinfo,paymenthubs,paymenthubsprops,orderstatuscode,paymenthub,stripePromises/*,loadOrderInfo*/]);
	

	const disableSubmit=function(event){
		event.stopPropagation();
		event.preventDefault();
		return false;
	};

	const CanBeSubmittedWarn=async function(){
		if(orderinfo);else return 'No order';
		if(orderinfo.customer);else return 'No customer';
		if(orderstatuscode==='new');else return 'Invalid status';
		if(orderinfo.customer_firstname);else return 'Manca il nome';
		if(orderinfo.customer_lastname);else return 'Manca il cognome';
		if(orderinfo.customer_email);else return 'Manca l indirizzo email';
		if(orderinfo.hasshipment){
			if(orderinfo.shipment_firstname);else return 'Manca il nome nell indirizzo di spedizione';
			if(orderinfo.shipment_lastname);else return 'Manca il cognome nell indirizzo di spedizione';
			if(orderinfo.shipment_address);else return 'Manca l indirizzo nell indirizzo di spedizione';
		}
		if(orderinfo.hasbilling){
			if(orderinfo.billing_iscompany){
				if(orderinfo.billing_name);else return 'Manca la Ragione Sociale';
				if(orderinfo.billing_vat);else return 'Manca la Partita IVA';
				if(orderinfo.billing_sdi);else return 'Manca il Codice Univoco SDI';
			}else{
				if(orderinfo.billing_ssn);else return 'Manca il Codice Fiscale';
			}
			if(orderinfo.billingaddress_firstname);else return 'Manca il Nome nell indirizzo di fatturazione';
			if(orderinfo.billingaddress_lastname);else return 'Manca il Cognome nell indirizzo di fatturazione';
			if(orderinfo.billingaddress_address);else return 'Manca l Indirizzo nell indirizzo di fatturazione';
		}
		if(orderinfo.terms_sales);else return 'Devi accettare le condizioni di acquisto';
		if(orderinfo.terms_cancel);else return 'Devi accettare le condizioni di cancellazione';
		if(paymenthubs && paymenthub);else return 'Devi scegliere una modalità di pagamento';
		if(paymenthubs[paymenthub].hub==='stripe'){
			try{
				let b=await stripePromises[paymenthub];
				if(b);else return 'Stripe non pronto';

				if(stripeElementsO);else return "Stripe Element non pronto";
			}catch(err){
				return 'Errore nel caricamento di Stripe';
			}
		}else return 'Modalità di pagamento non supportata';
		//TODO:payment

		return null;
	};
	const CanBeSubmitted=async function(){
		let err=await CanBeSubmittedWarn();
		if(err){
            			notifyWarn(
            			    process.env.PUBLIC_URL+'alert.svg',
            			    'Errore',
            			    <>{err}</>,
            			);
			return false;
		}
		return true;
		//TODO
	};

	const DoBuy=async function(){
		if(isSubmitting)return;
		setIsSubmitting(true);
		const r=await uscall(appcontext,auth,'order','order','order_checkpaymenthub',encodeURIComponent(order)+'/'+encodeURIComponent(paymenthub)+'/',{
			return_immediately:true,
			option_advance:orderinfo.option_advance,
			shipment_save:orderinfo.shipment_save,
			shipment_firstname:orderinfo.shipment_firstname,
			shipment_lastname:orderinfo.shipment_lastname,
			shipment_address:orderinfo.shipment_address,
			shipment_at:orderinfo.shipment_at,
			shipment_mobile:orderinfo.shipment_mobile,
			hasbilling:orderinfo.hasbilling,
			billing_save:orderinfo.billing_save,
			billing_iscompany:orderinfo.billing_iscompany,
			billing_name:orderinfo.billing_name,
			billing_vat:orderinfo.billing_vat,
			billing_sdi:orderinfo.billing_sdi,
			billing_pec:orderinfo.billing_pec,
			billing_ssn:orderinfo.billing_ssn,
			billingaddress_asshipment:orderinfo.billingaddress_asshipment,
			billingaddress_firstname:orderinfo.billingaddress_firstname,
			billingaddress_lastname:orderinfo.billingaddress_lastname,
			billingaddress_address:orderinfo.billingaddress_address,
			billingaddress_at:orderinfo.billingaddress_at,
			billingaddress_mobile:orderinfo.billingaddress_mobile,
			terms_sales:orderinfo.terms_sales,
			terms_cancel:orderinfo.terms_cancel
		});
		if(r && typeof(r['error'])!='undefined'){
			setIsSubmitting(false);
            		notifyWarn(
            		    process.env.PUBLIC_URL+'alert.svg',
            		    'Errore',
            		    <>{r.error.description}</>,
            		);
			return;
		}
		if(paymenthubs[paymenthub].hub==='stripe'){
			const {errorsubmit} = await stripeElementsO.submit();
			if(errorsubmit){
				setIsSubmitting(false);
            			notifyWarn(
            			    process.env.PUBLIC_URL+'alert.svg',
            			    'Errore',
            			    <>{errorsubmit.message}</>,
            			);
			return;
			}
			
			const mystripe=await stripePromises[paymenthub];
//console.log('confirm');console.log(paymenthubsprops[paymenthub]['paymenthub_'+paymenthub+'_setupintent_clientsecret']);
			const {error} = await mystripe.confirmSetup({
				elements:stripeElementsO,
				clientSecret:paymenthubsprops[paymenthub]['paymenthub_'+paymenthub+'_setupintent_clientsecret'],
				//allow_redisplay:'limited',
				confirmParams:{
					 return_url: document.location.href,
				}
			});
			setIsSubmitting(false);
			if(error){
            			notifyWarn(
            			    process.env.PUBLIC_URL+'alert.svg',
            			    'Errore',
            			    <>{error.message}</>,
            			);
			}else
				navigate(document.location.href);
		}
		
		//TODO
	};

	const PerformBuy=async function(event){
		event.stopPropagation();
		event.preventDefault();

		if(await CanBeSubmitted()){
			await DoBuy();
		}
	};

	const setCurPaymentHub=function(event){
		if(orderstatuscode && orderstatuscode==='new')
			setPaymentHub(event.target.value);
	};

	return (<>
		{orderinfo && orderstatuscode?(<>
			{orderstatuscode==='new' || orderstatuscode==='waitforpayment'?
				(<form onSubmit={disableSubmit}>
					<div className="checkout-container">
						<div className='checkout-body'>
							<div className="account-top">
								<div className="row h-100 w-100 align-items-center">
                    							<PrevPage />
									<div className="col-10 text-center">
										<h1>Checkout</h1>
									</div>
									<div className="col-1"></div>
								</div>
							</div>
							<div className="account-body">
								<>{orderinfo? (<>
									<CheckoutContact orderinfo={orderinfo} />
									<CheckoutOptions orderinfo={orderinfo} updateOrderData={updateOrderData} />
									<CheckoutShipment orderinfo={orderinfo} updateOrderData={updateOrderData} />
									</>):(<></>)
								}</>
								<>{orderstatuscode==='new'?(<>
									<h2>Metodi di pagamento</h2>
									<CheckoutPaymentStripe orderinfo={orderinfo} myhubcode='stripe_edu' paymenthubs={paymenthubs} paymenthub={paymenthub} setCurPaymentHub={setCurPaymentHub} stripePromises={stripePromises} stripeOptions={stripeOptions} stripeElementsO={stripeElementsO} setStripeElementsO={setStripeElementsO} />
									<CheckoutPaymentSepa orderinfo={orderinfo} myhubcode='sepa' paymenthubs={paymenthubs} paymenthub={paymenthub} setCurPaymentHub={setCurPaymentHub} />
									<CheckoutPaymentScalaPay orderinfo={orderinfo} myhubcode='scalapay' paymenthubs={paymenthubs} paymenthub={paymenthub} setCurPaymentHub={setCurPaymentHub} />
									<CheckoutPaymentCartaStudente orderinfo={orderinfo} myhubcode='cartastudente' paymenthubs={paymenthubs} paymenthub={paymenthub} setCurPaymentHub={setCurPaymentHub} />
								</>):(<></>)}</>
								<CheckoutBilling orderinfo={orderinfo} SwapCheckbox={SwapCheckbox} setOrderInfo={setOrderInfo} updateOrderData={updateOrderData} />
								<div className='row pt-5'>
									<div className='col-6 terms'>
										<div className='mb-3'>
											<input type='checkbox' name='terms_sales' checked={orderinfo && orderinfo.terms_sales} onClick={SwapCheckbox} />
											<p>Completando l'acquisto accetti le condizioni d’uso e vendita di G.Giappichelli Editore. Consulta la nostra <a href="">Informativa sulla privacy</a> e sulle <a href="">condizioni di vendita</a>.</p>
										</div>
										<div>
											<input type='checkbox' name='terms_cancel' checked={orderinfo && orderinfo.terms_cancel} onClick={SwapCheckbox} />
											<p>Cliccando accetti le condzioni di recesso e/o rimborso di G.Giappcihelli Editore.</p>
										</div>
									</div>
									<div className='col-6'>
										<input type="submit" className='button gradient-button' value="Completa l'acquisto" onClick={PerformBuy}/>
									</div>
								</div>
							</div>
                    				</div>
                				<div className='checkout-sidebar'>
							<CheckoutSidebar orderinfo={orderinfo} orderitems={orderitems} producttypes={producttypes} loadOrderInfo={loadOrderInfo} />
                				</div>
					</div>
				</form>):(<>{orderstatuscode==='payed'?(<>
					<div className='page w-100'>
						<div className='row h-100 g-0'>
							<div className='col-6 page-background'></div>
							<div className='col-6 page-right'>
								<h1>Ordine confermato</h1>
								<p>Grazie per il tuo acquisto!</p>
								<p>Abbiamo aggiunto i nuovi prodotti nella tua Dashboard!</p>
								<a href='/' className='gradient-button align-self-center'>Vai alla Dashboard</a>
							</div>
						</div>
					</div>

				</>):(<>
					<div className='page w-100'>
						<div className='row h-100 g-0'>
							<div className='col-6 page-background'></div>
							<div className='col-6 page-right'>
								<h1>Ordine annullato/scaduto o interrotto</h1>
							</div>
						</div>
					</div>
				</>)}</>)}</>):(<>
					<div className='page w-100'>
						<div className='row h-100 g-0'>
							<div className='col-6 page-background'></div>
							<div className='col-6 page-right'>
								<h1>Loading...</h1>
							</div>
						</div>
					</div>
			</>)}</>);
}

export default Checkout;
