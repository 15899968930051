import './landing.css';

function ActivateLanding() {
   
    return (
        <section className='activate-landing'>
            <div className="activate-content">
                <div className='activate-left'>
                    <img src={`${process.env.PUBLIC_URL}/activate-img.png`} alt="Attiva prodotto" />
                </div>
                <div className='activate-right'>
                    <h1>Attiva un prodotto</h1>
                    <h2>Inserisci il codice univoco e accedi alle risorse integrative incluse nel tuo manuale.</h2>
                    <p>Trova il codice all'interno del tuo volume, clicca su Attiva, Registrati e Accedi all'eBook. Benvenuto nel tuo ambiente digitale di studio e lettura.</p>
                    <hr />
                    <button className='gradient-button'>Attiva un prodotto  <img src={`${process.env.PUBLIC_URL}/arrow-w.svg`} alt="Attiva prodotto" /></button>
                </div>
            </div>
        </section>
    )
}


export default ActivateLanding;