import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { CustomButtonGroup } from '../../utilities/customArrowsCarousel';
import { useEffect,useState,useContext } from 'react';
import { useAuth } from 'react-oidc-context';
import AppContext from '../../../AppContext';
import {uscall,getresourceurl/*,getfirst*/,usurl,mergeorders} from '../../../us';
import CommonContext from '../../../CommonContext';
import UserContext from '../../../UserContext';

const YourProductsBox = () => {
    const auth = useAuth();
    const appcontext = useContext(AppContext);
    const usercontext = useContext(UserContext);
        const commoncontext = useContext(CommonContext);

	const [showbooks,setShowBooks]=useState(true);
	const [showriviste,setShowRiviste]=useState(true);
	const [showcorsi,setShowCorsi]=useState(true);

	const [producttypes,setProductTypes]=useState(null);

    const [lastBuys,setLastBuys]=useState(null);
   // const [lastBuyProducts,setLastBuyProducts]=useState(null);
    const [lastProducts,setLastProducts]=useState(null);
    const [lastProducts_books,setLastProducts_books]=useState(null);
    const [lastProducts_riviste,setLastProducts_riviste]=useState(null);
    const [lastProducts_corsi,setLastProducts_corsi]=useState(null);

   // const [productimageurls,setProductimageurls]=useState({});
   // const [productviewurls,setProductviewurls]=useState({});

    useEffect(() => {
	const cb=async function(){
		setProductTypes(commoncontext.producttypes);
		//const r=await uscall(appcontext,auth,'ecommerce',null,'producttype_search',null,null);
		//if(typeof(r['error'])!='undefined');
		//else if(typeof(r['result'])!='undefined' && r.result){
		//	const pt={};
		//	for(let i=0;i<r.result.length;i++)pt[r.result[i].uuid]=r.result[i];
		//	setProductTypes(pt);
		//}
	}
	if(producttypes);else if(commoncontext && commoncontext.producttypes)
		cb();
    },[auth,appcontext,commoncontext,producttypes]);




    useEffect(() => {
	const loadLocalOrders=async function(){
		let r2=await uscall(appcontext,auth,'order','order','myactiveorders',null,{sort:{field:'committed',desc:true}});
		let rp=await uscall(appcontext,auth,'order','order','mypayments',null,{sort:{field:'committed',desc:true}});
		let status_tbl=[];
		for(let i=0;i<r2.result.length;i++){if(status_tbl.includes(r2.result[i].status));else status_tbl.push(r2.result[i].status);}
		let statuses=await uscall(appcontext,auth,'order','orderstatus','load',null,status_tbl);
		let items_tbl=[];
		for(let i=0;i<r2.result.length;i++)items_tbl=items_tbl.concat(r2.result[i].items);
		let items=await uscall(appcontext,auth,'order','orderitem','load',null,items_tbl);
		let products_tbl=[];
		for(let i in items.result)products_tbl.push(items.result[i].product);
		let products=await uscall(appcontext,auth,'pim','product','load',null,products_tbl);
		let paymethod_tbl=[];
		for(let j=0;j<rp.result.length;j++){if(paymethod_tbl.includes(rp.result[j].paymentmethod));else paymethod_tbl.push(rp.result[j].paymentmethod);}
		let paymentmethods=await uscall(appcontext,auth,'order','paymentmethod','load',null,paymethod_tbl);

		let paystatus_tbl=[];
		for(let i=0;i<rp.result.length;i++){if(paystatus_tbl.includes(rp.result[i].status));else paystatus_tbl.push(rp.result[i].status);}
		let paystatuses=await uscall(appcontext,auth,'order','paymentstatus','load',null,paystatus_tbl);

		for(let i=0;i<r2.result.length;i++){
			let myitems=[];
			for(let j=0;j<r2.result[i].items.length;j++){
				let item=items.result[r2.result[i].items[j]];
				item.product=products.result[item.product];
				myitems.push(item);
			}
			r2.result[i].status=statuses.result[r2.result[i].status].code;
			r2.result[i].items=myitems;
			let payments=[];
			for(let j=0;j<rp.result.length;j++){
				if(rp.result[j].order===r2.result[i].uuid){
					let myp=rp.result[j];
					myp.method=paymentmethods.result[myp.paymentmethod].code;
					myp.last4=myp.paymentmethod_stripesetup_cardlast4;
					myp.brand=myp.paymentmethod_stripesetup_cardbrand;
					if(paystatuses.result[myp.status].code==='payed')
						payments.push(myp);
				}
			}
			if(payments.length===1)
				r2.result[i].payment=payments[0];
			else
				r2.result[i].payment=payments;
		}
		return r2;
	};

		const cbpage=async function(page,size){
			const r=await uscall(appcontext,auth,'ecommerce',null,'order_getlastuserorders',null,{onlyactive:true,num:size,page:page/*num:pageSize,page:page*/});
			if(typeof(r['error'])!='undefined');
			else if(typeof(r['result'])!='undefined' && r.result){
//console.log('Offset '+page);for(let i=0;i<r.result.length;i++)console.log('      order '+r.result[i].ordercode);
				return r.result;
			}
			return [];
		};


	const cb=async function(){
//console.log('effect2');
		let lastb=null;
		const rlocal=await loadLocalOrders();
		if(typeof(rlocal['error'])!='undefined');
		else if(typeof(rlocal['result'])!='undefined' && rlocal.result){
			if(lastb)lastb=mergeorders(lastb,rlocal.result);
			else lastb=rlocal.result;
			setLastBuys(lastb)
		}
		//let n=0;
		let page=0;
		let numperpage=5;
		let rremote=[];
		do{
			rremote=await cbpage(page++,numperpage);
			if(rremote.length>0){
				if(lastb)lastb=mergeorders(lastb,rremote);
				else lastb=rremote;
				setLastBuys(lastb);
			}
		}while(rremote.length>=numperpage /*&& page<4*/);
	};
	if(usercontext && usercontext.user && (!lastBuys))
		cb();
    },[auth,appcontext,usercontext,lastBuys]);





    useEffect(() => {
	const cb=async function(){
		if(lastBuys /*&& typeof(lastBuys['items'])!='undefined'*/){
			const ps=[];
			const ps_books=[];
			const ps_riviste=[];
			const ps_corsi=[];
			for(let ki=0;ki<lastBuys.length;ki++){
				for(let k=0;k<lastBuys[ki].items.length;k++){
					//ps.push(lastBuys[ki].items[k]);
					const p=lastBuys[ki].items[k].product;
					if(p){
						if(p.copertina_url);
						else if(p.copertina)p.copertina_url=(await getresourceurl(appcontext,auth,p.copertina));
						else if(p.magento_custom && p.magento_custom.image)p.copertina_url=p.magento_custom.image;

						p.btn_label='Continua a leggere';
						if(p.open_url);
						else if(p.code==='abbonamentoedu')p.open_url=process.env.REACT_APP_PREFIX_MOODLEEDU;
						else if(p.type=='rivista' || (typeof(producttypes[p.type])!='undefined' && producttypes[p.type].code==='rivista'));
						else if(p.type=='corso' || (typeof(producttypes[p.type])!='undefined' && producttypes[p.type].code==='corso'));
						else if(p.uuid){
							p.open_url=await usurl(appcontext,auth,'reader','userproductsession','read',p.uuid+'/');
							const r=(await uscall(appcontext,auth,'reader','userproductsession','status',[p.uuid]));
							p.btn_label=(r && typeof(r['result'])!='undefined' && typeof(r['result']['startedreading'])!='undefined'?(r['result']['startedreading']?'Continua a leggere':'Inizia a leggere'):'Continua a leggere');
						}


						if(p.type=='rivista' || (typeof(producttypes[p.type])!='undefined' && producttypes[p.type].code==='rivista')/*p.isbn && p.issn*/)ps_riviste.push(p);
						else if(p.type=='paper_hard' || p.type=='paper_flex' || p.type=='ebook' || (typeof(producttypes[p.type])!='undefined' && (producttypes[p.type].code==='paper_hard' || producttypes[p.type].code==='paper_flex' || producttypes[p.type].code==='ebook'))/*'p.isbn*/)ps_books.push(p);
						else if(p.type=='corso' || (typeof(producttypes[p.type])!='undefined' && producttypes[p.type].code==='corso'))ps_corsi.push(p);
						else if(p.code==='abbonamentoedu')continue;
						ps.push(p);
					}
				}
			}
			//setLastBuyProducts(ps);
			//setLastProducts_books(ps_books);
			//setLastProducts_riviste(ps_riviste);
			//setLastProducts_corsi(ps_corsi);


			setLastProducts(ps);
			setLastProducts_books(ps_books);
			setLastProducts_riviste(ps_riviste);
			setLastProducts_corsi(ps_corsi);
		}
	}

	if(lastBuys && producttypes){
		//if(lastBuyProducts);else 
		//if(lastProducts);else 
			cb();
	}
    //},[auth,appcontext,lastBuys,lastBuyProducts]);
    },[auth,appcontext,lastBuys,producttypes]);
   /* useEffect(() => {
	const cb=async function(){
		const isbns=[];
		const riviste=[];
		const corsi=[];
		for(let ki=0;ki<lastBuyProducts.length;ki++){
			if(typeof(lastBuyProducts[ki].product.magento_custom['isbn'])!='undefined' && typeof(lastBuyProducts[ki].product.magento_custom['issn'])!='undefined')
				riviste.push({func:'and',fields:[{field:'isbn',func:'eq',value:lastBuyProducts[ki].product.magento_custom.isbn},{field:'issn',func:'eq',value:lastBuyProducts[ki].product.magento_custom.issn}]});
			else if(typeof(lastBuyProducts[ki].product.magento_custom['isbn'])!='undefined')
				isbns.push(lastBuyProducts[ki].product.magento_custom.isbn);
			else if(typeof(lastBuyProducts[ki].product['magento_sku'])!='undefined')
				corsi.push(lastBuyProducts[ki].product['magento_sku']);
		}
		let request={filter:{func:'or',fields:[]}};//{field:'ean',func:'isnull'}
		if(isbns.length>0)request.filter.fields.push({field:'isbn',func:'in',value:isbns});
		if(riviste.length>0)request.filter.fields.push({func:'or',fields:riviste});
		if(corsi.length>0)request.filter.fields.push({field:'ean',func:'in',value:corsi});
		
		let r={result:[]};
		if(request.filter.fields.length>0)
			r=await uscall(appcontext,auth,'ecommerce',null,'product_search',null,request);
		let outbooks=[];
		let outriviste=[];
		let outcorsi=[];
		for(let i=0;i<r.result.length;i++){
			if(producttypes[r.result[i].type].code==='corso')outcorsi.push(r.result[i]);
			else if(producttypes[r.result[i].type].code==='rivista')outriviste.push(r.result[i]);
			else outbooks.push(r.result[i]);
		}
		setLastProducts(r.result);
		setLastProducts_books(outbooks);
		setLastProducts_riviste(outriviste);
		setLastProducts_corsi(outcorsi);
	}

	if(lastBuyProducts){
		if(lastProducts && producttypes);else 
			cb();
	}
    },[auth,appcontext,lastBuyProducts,lastProducts,lastProducts_books,lastProducts_riviste,lastProducts_corsi,setLastProducts_books,setLastProducts_riviste,setLastProducts_corsi,producttypes]);*/



    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3,
            slidesToSlide: 3
        },
        tablet: {
            breakpoint: { max: 1024, min: 768 },
            items: 2,
            slidesToSlide: 2
        },
        mobile: {
            breakpoint: { max: 768, min: 0 },
            items: 1,
            slidesToSlide: 1
        }
    };


	//console.log('r');console.log(lastBuys);console.log(lastProducts);
//console.log(productimageurls);

    return (<>
                <div className='dash-box h-460'>
                    <div className="row align-items-center">
                        <div className="col-md-auto">
                            <h1 className="mb-0">I tuoi prodotti</h1>
                        </div>
                        <div className="col-md">
                            <button className={`yellow-button ${showbooks && showriviste && showcorsi && " selected"}`} onClick={()=>{setShowBooks(true);setShowRiviste(true);setShowCorsi(true);}}>
                                <span>Tutti {lastProducts && (<>{lastProducts.length}</>)}</span>
                            </button>
                            <button className={`yellow-button ${showbooks && (!showriviste) && (!showcorsi) && " selected"}`} onClick={()=>{setShowBooks(true);setShowRiviste(false);setShowCorsi(false);}}>
                                <span>Libri ({lastProducts_books && (<>{lastProducts_books.length}</>)})</span>
                            </button>
                            <button className={`yellow-button ${(!showbooks) && (!showriviste) && (showcorsi) && " selected"}`} onClick={()=>{setShowBooks(false);setShowRiviste(false);setShowCorsi(true);}}>
                                <span>Corsi ({lastProducts_corsi && (<>{lastProducts_corsi.length}</>)})</span>
                            </button>
                            <button className={`yellow-button ${(!showbooks) && (showriviste) && (!showcorsi) && " selected"}`} onClick={()=>{setShowBooks(false);setShowRiviste(true);setShowCorsi(false);}}>
                                <span>Riviste ({lastProducts_riviste && (<>{lastProducts_riviste.length}</>)})</span>
                            </button>
                        </div>
                    </div>
                    <div className='custom-carousel-container w-100 h-100'>
				{lastProducts?(
					<>{lastProducts.length>0?(<>
					{showbooks && showriviste && showcorsi ? (<>
			                        <Carousel
			                            arrows={false}
			                            renderButtonGroupOutside={true}
			                            customButtonGroup={<CustomButtonGroup />}
			                            swipeable={true}
			                            draggable={true}
			                            showDots={false}
			                            responsive={responsive}
			                            ssr={true} // means to render carousel on server-side.
			                            infinite={true}
			                            autoPlay={false}
			                            autoPlaySpeed={1000}
			                            keyBoardControl={true}
			                            customTransition="transform 0.5s ease-in-out"
			                            transitionDuration={500}
			                            containerClass="carousel-container"
			                            removeArrowOnDeviceType={["tablet", "mobile"]}
			                            dotListClass="custom-dot-list-style"
			                        >
						{lastProducts.map(product => (
					                            <div key={product.uuid?product.uuid:product.magento_product_id}>
					                                <div className='prod-cover'>
					                                    {/*<button className='chip'><span>Libro</span></button> */}
					                                    <img src={product.copertina_url?product.copertina_url:`${process.env.PUBLIC_URL}/cover1.jpg`} alt={product.name} />
					                                    <div className='overlay'>
					                                        <h6>{product.name}</h6>
					                                    </div>
														<div>
															{product.open_url || product.url?(<a href={product.open_url?product.open_url:(product.url?product.url:null)} className='purple-button'>{product.btn_label}</a>):(<></>)}
															<a href="/my-products/" className='border-button'>Gestisci</a>
														</div>
					                                </div>
					                            </div>
						))}
                        			</Carousel>
					</>):(<>
						{showcorsi?(<>
			                        <Carousel
			                            arrows={false}
			                            renderButtonGroupOutside={true}
			                            customButtonGroup={<CustomButtonGroup />}
			                            swipeable={true}
			                            draggable={true}
			                            showDots={false}
			                            responsive={responsive}
			                            ssr={true} // means to render carousel on server-side.
			                            infinite={true}
			                            autoPlay={false}
			                            autoPlaySpeed={1000}
			                            keyBoardControl={true}
			                            customTransition="transform 0.5s ease-in-out"
			                            transitionDuration={500}
			                            containerClass="carousel-container"
			                            removeArrowOnDeviceType={["tablet", "mobile"]}
			                            dotListClass="custom-dot-list-style"
			                        >
						{lastProducts_corsi.map(product => (
					                            <div key={product.uuid?product.uuid:product.magento_product_id}>
					                                <div className='prod-cover'>
					                                    <button className='chip'><span>Corso</span></button>
					                                    <img src={product.copertina_url?product.copertina_url:`${process.env.PUBLIC_URL}/cover1.jpg`} alt={product.name} />
					                                    <div className='overlay'>
					                                        <h6>{product.name}</h6>
					                                    </div>
														<div>
											{product.open_url || product.url?(<a href={product.open_url?product.open_url:(product.url?product.url:null)} className='purple-button'>{product.btn_label}</a>):(<></>)}
															<a href="/my-products/" className='border-button'>Gestisci</a>
														</div>
					                            	</div>
												</div>
						))}
                        			</Carousel>
						</>):(<>
							{showriviste?(<>
			                        <Carousel
			                            arrows={false}
			                            renderButtonGroupOutside={true}
			                            customButtonGroup={<CustomButtonGroup />}
			                            swipeable={true}
			                            draggable={true}
			                            showDots={false}
			                            responsive={responsive}
			                            ssr={true} // means to render carousel on server-side.
			                            infinite={true}
			                            autoPlay={false}
			                            autoPlaySpeed={1000}
			                            keyBoardControl={true}
			                            customTransition="transform 0.5s ease-in-out"
			                            transitionDuration={500}
			                            containerClass="carousel-container"
			                            removeArrowOnDeviceType={["tablet", "mobile"]}
			                            dotListClass="custom-dot-list-style"
			                        >
						{lastProducts_riviste.map(product => (
					                            <div key={product.uuid?product.uuid:product.magento_product_id}>
					                                <div className='prod-cover'>
					                                    <button className='chip'><span>Rivista</span></button>
					                                    <img src={product.copertina_url?product.copertina_url:`${process.env.PUBLIC_URL}/cover1.jpg`} alt={product.name} />
					                                    <div className='overlay'>
					                                        <h6>{product.name}</h6>
					                                    </div>
														<div>
											{product.open_url || product.url?(<a href={product.open_url?product.open_url:(product.url?product.url:null)} className='purple-button'>{product.btn_label}</a>):(<></>)}
															<a href="/my-products/" className='border-button'>Gestisci</a>
					                                </div>
													</div>
					                            </div>
						))}
                        			</Carousel>
							</>):(<>
			                        <Carousel
			                            arrows={false}
			                            renderButtonGroupOutside={true}
			                            customButtonGroup={<CustomButtonGroup />}
			                            swipeable={true}
			                            draggable={true}
			                            showDots={false}
			                            responsive={responsive}
			                            ssr={true} // means to render carousel on server-side.
			                            infinite={true}
			                            autoPlay={false}
			                            autoPlaySpeed={1000}
			                            keyBoardControl={true}
			                            customTransition="transform 0.5s ease-in-out"
			                            transitionDuration={500}
			                            containerClass="carousel-container"
			                            removeArrowOnDeviceType={["tablet", "mobile"]}
			                            dotListClass="custom-dot-list-style"
			                        >
						{lastProducts_books.map(product => (
					                            <div key={product.uuid?product.uuid:product.magento_product_id}>
					                                <div className='prod-cover'>
					                                    <button className='chip'><span>Libro</span></button>
					                                    <img src={product.copertina_url?product.copertina_url:`${process.env.PUBLIC_URL}/cover1.jpg`} alt={product.name} />
					                                    <div className='overlay'>
					                                        <h6>{product.name}</h6>
					                                    </div>
														<div>
															{product.open_url || product.url?(<a href={product.open_url?product.open_url:(product.url?product.url:null)} className='purple-button'>{product.btn_label}</a>):(<></>)}
															<a href="/my-products/" className='border-button'>Gestisci</a>
														</div>
													</div>
					                            </div>
						))}
                        			</Carousel>
							</>)}
						</>)}
					</>)}
				</>):(<></>)}
				</>):(
			                        <Carousel
			                            arrows={false}
			                            renderButtonGroupOutside={true}
			                            customButtonGroup={<CustomButtonGroup />}
			                            swipeable={true}
			                            draggable={true}
			                            showDots={false}
			                            responsive={responsive}
			                            ssr={true} // means to render carousel on server-side.
			                            infinite={true}
			                            autoPlay={false}
			                            autoPlaySpeed={1000}
			                            keyBoardControl={true}
			                            customTransition="transform 0.5s ease-in-out"
			                            transitionDuration={500}
			                            containerClass="carousel-container"
			                            removeArrowOnDeviceType={["tablet", "mobile"]}
			                            dotListClass="custom-dot-list-style"
			                        >
					                            <div key="fake1">
					                                <div className='prod-cover'>
					                                    <img src={`${process.env.PUBLIC_URL}/cover1.jpg`}  />
					                                    <div className='overlay'>
					                                        <h6></h6>
					                                    </div>
					                                </div>
					                                <div>
					                                </div>
					                            </div>
					                            <div key="fake2">
					                                <div className='prod-cover'>
					                                    <img src={`${process.env.PUBLIC_URL}/cover1.jpg`}  />
					                                    <div className='overlay'>
					                                        <h6></h6>
					                                    </div>
					                                </div>
					                                <div>
					                                </div>
					                            </div>
					                            <div key="fake3">
					                                <div className='prod-cover'>
					                                    <img src={`${process.env.PUBLIC_URL}/cover1.jpg`}  />
					                                    <div className='overlay'>
					                                        <h6></h6>
					                                    </div>
					                                </div>
					                                <div>
					                                </div>
					                            </div>
                        			</Carousel>
				)}
                    </div>
                </div>
        
    </>);
}

export default YourProductsBox;
