import { useParams } from 'react-router-dom';
import './landing.css';
import BoxLanding from './box-landing';

function BannerLanding() {
    const { landingPage } = useParams();

    const backgroundImages = {
        landing: 'url(/landing/landing-banner-1.png)',
        edu: 'url(/landing/landing-banner-4.png)',
        universita: 'url(/landing/landing-banner-2.png)',
        studenti:'url(/landing/landing-banner-2.png)',
        docenti: 'url(/landing/landing-banner-2.png)', 
        professionali:  'url(/landing/landing-banner-3.png)', 
        'enti-biblioteche':  'url(/landing/landing-banner-1.png)',
        'biblioteca-digitale':  'url(/landing/landing-banner-1.png)',
        default: 'url(/landing/landing-banner-1.png)'
    };

    const backgroundImage = backgroundImages[landingPage] || backgroundImages.default;

    return (
        <section className="banner-landing" style={{ backgroundImage: backgroundImage }}>
            <div className='banner-overlay'></div>
            <div className='banner-headings'> 
                <h1>Da oltre 100 anni, il nostro lavoro è supportarti</h1>
                <h2>Con proposte editoriali, piattaforme per lo studio e la formazione professionale.</h2>
            </div>
            <BoxLanding />
        </section>
    );
}

export default BannerLanding;
