/* https://fkhadra.github.io/react-toastify/introduction */

import { toast } from 'react-toastify';
import './toaster.css';
import CustomToastContent from './toastContainer';

export const notifyDefault = (message) => {
    toast(message);
};

export const notifySuccess = (message, className) => {
    toast.success(message, {
        position: "top-center",
        className: className
    });
};

export const notifyError = (message, className) => {
    toast.error(message, {
        position: "top-center",
        className: className
    });
};

export const notifyWarn = (iconPath, title, message, link, className) => {
    toast.warn(
        <CustomToastContent iconPath={iconPath} title={title} message={message} link={link} />,
        {
            position: "top-center",
            autoClose: false,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            className: className
        }
    );
};

export const notifyInfo = (message, className) => {
    toast.info(message, {
        position: "top-center",
        className: className
    });
};

export const notifyCustom = (message, className) => {
    toast(message, {
        position: "top-center",
        className: className
    });
};