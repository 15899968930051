import './myproducts.css';
import { /*Link,*/useNavigate } from 'react-router-dom';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import PurchaseHistory from './purchaseHistory';
import { useEffect,useState,useContext } from 'react';
import { useAuth } from 'react-oidc-context';
import AppContext from '../../AppContext';
import {uscall/*,getresourceurl*//*,getfirst*//*,usurl*/,mergeorders} from '../../us';
import CommonContext from '../../CommonContext';
import MyProduct from './myproduct';
import UserContext from '../../UserContext';
import PrevPage from '../utilities/prevPage';

const MyProducts = () => {
    const auth = useAuth();
    const usercontext = useContext(UserContext);
    const appcontext = useContext(AppContext);
        const commoncontext = useContext(CommonContext);
    let navigate = useNavigate();
	const [producttypes,setProductTypes]=useState(null);
    const [lastBuys,setLastBuys]=useState(null);
    //const [lastBuyProducts,setLastBuyProducts]=useState(null);
    const [activeOrders,setActiveOrders]=useState(null);
    const [inactiveOrders,setInactiveOrders]=useState(null);
    //const [page,setCurPage]=useState(1);
    //const pageSize=5;
    //const [local,setLocal]=useState(null);
    //const [remote,setRemote]=useState(null);

    useEffect(() => {
      if(auth.isAuthenticated);
      else 
        navigate('/login/?redirect='+encodeURIComponent(document.location.href));
    },[auth,navigate]);
    useEffect(() => {
	const cb=async function(){
		setProductTypes(commoncontext.producttypes);
	}
	if(producttypes);else if(commoncontext && commoncontext.producttypes)
		cb();
    },[auth,appcontext,commoncontext,producttypes]);

    useEffect(() => {
	const loadLocalOrders=async function(){
		let r2=await uscall(appcontext,auth,'order','order','myorders',null,{sort:{field:'committed',desc:true}});
		let rp=await uscall(appcontext,auth,'order','order','mypayments',null,{sort:{field:'committed',desc:true}});
		let status_tbl=[];
		for(let i=0;i<r2.result.length;i++){if(status_tbl.includes(r2.result[i].status));else status_tbl.push(r2.result[i].status);}
		let statuses=await uscall(appcontext,auth,'order','orderstatus','load',null,status_tbl);
		let items_tbl=[];
		for(let i=0;i<r2.result.length;i++)items_tbl=items_tbl.concat(r2.result[i].items);
		let items=await uscall(appcontext,auth,'order','orderitem','load',null,items_tbl);
		let products_tbl=[];
		for(let i in items.result)products_tbl.push(items.result[i].product);
		let products=await uscall(appcontext,auth,'pim','product','load',null,products_tbl);
		let paymethod_tbl=[];
		for(let j=0;j<rp.result.length;j++){if(paymethod_tbl.includes(rp.result[j].paymentmethod));else paymethod_tbl.push(rp.result[j].paymentmethod);}
		let paymentmethods=await uscall(appcontext,auth,'order','paymentmethod','load',null,paymethod_tbl);

		let paystatus_tbl=[];
		for(let i=0;i<rp.result.length;i++){if(paystatus_tbl.includes(rp.result[i].status));else paystatus_tbl.push(rp.result[i].status);}
		let paystatuses=await uscall(appcontext,auth,'order','paymentstatus','load',null,paystatus_tbl);

		for(let i=0;i<r2.result.length;i++){
			let myitems=[];
			for(let j=0;j<r2.result[i].items.length;j++){
				let item=items.result[r2.result[i].items[j]];
				item.product=products.result[item.product];
				myitems.push(item);
			}
			r2.result[i].status=statuses.result[r2.result[i].status].code;
			r2.result[i].items=myitems;
			let payments=[];
			for(let j=0;j<rp.result.length;j++){
				if(rp.result[j].order===r2.result[i].uuid){
					let myp=rp.result[j];
					myp.method=paymentmethods.result[myp.paymentmethod].code;
					myp.last4=myp.paymentmethod_stripesetup_cardlast4;
					myp.brand=myp.paymentmethod_stripesetup_cardbrand;
					if(paystatuses.result[myp.status].code==='payed')
						payments.push(myp);
				}
			}
			if(payments.length===1)
				r2.result[i].payment=payments[0];
			else
				r2.result[i].payment=payments;
		}
		return r2;
	};

		const cbpage=async function(page,size){
			const r=await uscall(appcontext,auth,'ecommerce',null,'order_getlastuserorders',null,{num:size,page:page/*num:pageSize,page:page*/});
			if(typeof(r['error'])!='undefined');
			else if(typeof(r['result'])!='undefined' && r.result){
//console.log('Offset '+page);for(let i=0;i<r.result.length;i++)console.log('      order '+r.result[i].ordercode);
				return r.result;
			}
			return [];
		};


	const cb=async function(){
		let lastb=null;
		const rlocal=await loadLocalOrders();
		if(typeof(rlocal['error'])!='undefined');
		else if(typeof(rlocal['result'])!='undefined' && rlocal.result){
			if(lastb)lastb=mergeorders(lastb,rlocal.result);
			else lastb=rlocal.result;
			setLastBuys(lastb)
		}
		//let n=0;
		let page=0;
		let numperpage=5;
		let rremote=[];
		do{
			rremote=await cbpage(page++,numperpage);
			if(rremote.length>0){
				if(lastb)lastb=mergeorders(lastb,rremote);
				else lastb=rremote;
				setLastBuys(lastb)
			}
		}while(rremote.length>=numperpage);
	};
	if(usercontext && usercontext.user && (!lastBuys))
		cb();
    },[auth,appcontext,usercontext,lastBuys]);

    useEffect(() => {
	if(lastBuys!==null){
		let active=[];
		let inactive=[];
		for(let ki=0;ki<lastBuys.length;ki++){
			let order=lastBuys[ki];
			if(order.status==='payed')active.push(order);
			else inactive.push(order);
		}
		setActiveOrders(active);
		setInactiveOrders(inactive);
//console.log('Update');for(let i=0;i<active.length;i++)console.log('      order '+active[i].ordercode);
	}
    },[auth,appcontext,lastBuys]);

	//const loadMore=function(){
	//	setCurPage(page+1);
	//};

//console.log('Render');if(activeOrders!==null){for(let i=0;i<activeOrders.length;i++){console.log('      order '+activeOrders[i].ordercode);if(activeOrders[i].ordercode=='1000110890')console.log(activeOrders[i]);}}

    return (
        <div className="account-container">
            <div className="account-top">
                <div className="row h-100 align-items-center">
					<PrevPage />
                    <div className="col-10 text-center">
                        <h1>I miei prodotti</h1>
                    </div>
                    <div className="col-1"></div>
                </div>
            </div>
            <div className="account-body">
                <h2>Prodotti</h2>
                <div className='tabs-container'>
                    <Tabs
                    defaultActiveKey="attivi"
                    id="tabproducts"
                    >
                        <Tab eventKey="attivi" title="Attivi">
				<>{activeOrders===null?(<>
					<div className="row text-center">
						<div className="col-12 col-lg-8 offset-lg-2 mb-2">
		                			<h4>Caricamento in corso...</h4>
						</div>
					</div>
				</>):(
				<>{activeOrders.length>0?(<>
				{activeOrders.map(order=>{
					return (<>{order.items.map(item=>{
						return (<MyProduct order={order} item={item} producttypes={producttypes} />)
					})}</>)
				})}
				</>):(<>
					<div className="row text-center noproducts">
						<div className="col-12 col-lg-8 offset-lg-2">
		                			<h3>Non hai prodotti attivi</h3>
						</div>
					</div>
					<div className="row text-center">
						<div className="col-12 col-lg-8 offset-lg-2 mb-2">
		                			<h4>Scopri i nostri prodotti</h4>
						</div>
					</div>
					<div className="row text-center">
						<div className="col-12 col-lg-8 offset-lg-2">
							<a href={`${process.env.REACT_APP_PREFIX_MAGENTO}/novita`} className='gradient-button align-self-center'>Vai al catalogo libri</a>
						</div>
					</div>
				</>)}</>
				)}</>
                        </Tab>
                        <Tab eventKey="inattivi" title="Inattivi">
				<>{inactiveOrders===null?(<>
					<div className="row text-center">
						<div className="col-12 col-lg-8 offset-lg-2 mb-2">
		                			<h4>Caricamento in corso...</h4>
						</div>
					</div>
				</>):(
				<>{inactiveOrders.length>0?(<>
				{inactiveOrders.map(order=>{
					return (<>{order.items.map(item=>{
						return (<MyProduct order={order} item={item} producttypes={producttypes} />)
					})}</>)
				})}
				</>):(<>
					<div className="row text-center noproducts">
						<div className="col-12 col-lg-8 offset-lg-2">
		                			<h3>Non hai prodotti inattivi</h3>
						</div>
					</div>
					<div className="row text-center">
						<div className="col-12 col-lg-8 offset-lg-2 mb-2">
		                			<h4>Scopri i nostri prodotti</h4>
						</div>
					</div>
					<div className="row text-center">
						<div className="col-12 col-lg-8 offset-lg-2">
							<a href={`${process.env.REACT_APP_PREFIX_MAGENTO}/novita`} className='gradient-button align-self-center'>Vai al catalogo libri</a>
						</div>
					</div>
				</>)}</>
				)}</>
                        </Tab>
                    </Tabs>
                   {/* <button className='gradient-button reset-button' onClick={loadMore}><span>Carica successivi</span></button>*/}
                </div>
                <PurchaseHistory orders={activeOrders} producttypes={producttypes} />
            </div>
        </div>
    )

}

export default MyProducts;
