import { Link } from 'react-router-dom';
import './landing.css';
import Accordion from 'react-bootstrap/Accordion';

export default function LandingEdu() {
	return (
            <section className="landing-edu">
                  <div className='edu-top'>
                        <dvi className="top-headings">
                              <h1>Un abbonamento semestrale a XXX+ risorse didattiche per passare i tuoi esami di giurisprudenza.</h1>
                              <h2>Approfitta subito della promo -50% <span>(€9,80/mese)</span></h2>
                        </dvi>
                  </div>
                  <div className='edu-cards'>
                        <div className='card'>
                              <div className='card-content'>
                                    <div className='content-top'>
                                          <div className='top-info'></div>
                                          <div className='bottom-info'>
                                                <div className='promo'>
                                                      <div className='promo-headings'>
                                                            <h2>EDU</h2>
                                                            <span>per 6 mesi</span>
                                                      </div>
                                                      <div className='promo-chip'>
                                                            <span>Promo -50%</span>
                                                      </div>
                                                </div>
                                                <div className='pricing'>
                                                      <h2>€ 4,50 /mese</h2>
                                                      {/*<h3>Pagamento unico di €29,40 invece di €58,80</h3> */}
                                                </div>
                                                <div className='action'>
                                                      <Link to='/create-checkout/abbonamentoedu' className='gradient-button align-self-center'>Abbonati ora <img src={`${process.env.PUBLIC_URL}/arrow-w.svg`} /></Link>
                                                </div>
                                                <div className='list'>
                                                      <h4>Include:</h4>
                                                      <div className='list-items'>
                                                            <div>
                                                                  <img src={`${process.env.PUBLIC_URL}/landing/list-item.svg`} />
                                                                  <span className='bold'>Accesso illimitato a tutte le risorse didattiche EDU</span>
                                                            </div>
                                                            <div>
                                                                  <img src={`${process.env.PUBLIC_URL}/landing/list-item.svg`} />
                                                                  <div>
                                                                        <span className='bold'>Preparazione agli esami del 1° e 2° anno di giurisprudenza: </span>
                                                                        <span>diritto pubblico, diritto privato, diritto penale, diritto del lavoro, diritto amministrativo</span>
                                                                  </div>
                                                            </div>
                                                            <div>
                                                                  <img src={`${process.env.PUBLIC_URL}/landing/list-item.svg`} />
                                                                  <div>
                                                                        <span className='bold'>2000 quiz </span>
                                                                        <span>di ripasso e test di autovalutazione</span>
                                                                  </div>
                                                            </div>
                                                            <div>
                                                                  <img src={`${process.env.PUBLIC_URL}/landing/list-item.svg`} />
                                                                  <span className='bold'>Mappe concettuali delle materie</span>
                                                            </div>
                                                      </div>
                                                </div>
                                          </div>
                                    </div>
                              </div>
                        </div>
                        <div className='card disabled-card'>
                              <div className='overlay'></div>
                              <div className='card-content'>
                                    <div className='content-top'>
                                          <div className='top-info'>
                                                <div className='info-chip'>
                                                      Coming Soon
                                                </div>
                                          </div>
                                          <div className='bottom-info'>
                                                <div className='promo'>
                                                      <div className='promo-headings'>
                                                            <h2>eBook + EDU</h2>
                                                            <span>per 6 mesi</span>
                                                      </div>
                                                      <div className='promo-chip'>
                                                            <span>Include un manuale</span>
                                                      </div>
                                                </div>
                                                <div className='pricing'>
                                                      <h2>€ 6,50 /mese</h2>
                                                      <h3>Pagamento unico di € 41,40</h3>
                                                </div>
                                                <div className='action'>
                                                      <Link to='/create-checkout/abbonamentoedu' className='gradient-button align-self-center button-disabled'>Scegli il libro</Link>
                                                </div>
                                                <div className='list'>
                                                      <h4>Include:</h4>
                                                      <div className='list-items'>
                                                            <div>
                                                                  <img src={`${process.env.PUBLIC_URL}/landing/list-item.svg`} />
                                                                  <span>1 manuale Giappichelli in versione eBook</span>
                                                            </div>
                                                            <div>
                                                                  <img src={`${process.env.PUBLIC_URL}/landing/list-item.svg`} />
                                                                  <div>
                                                                        <span>Accesso illimitato a tutte le risorse didattiche EDU</span>
                                                                  </div>
                                                            </div>
                                                            <div>
                                                                  <img src={`${process.env.PUBLIC_URL}/landing/list-item.svg`} />
                                                                  <span>Prepara 6 esami di giurisprudenza</span>
                                                            </div>
                                                            <div>
                                                                  <img src={`${process.env.PUBLIC_URL}/landing/list-item.svg`} />
                                                                  <span className='bold'>Possibilità di cambio libro gratuito</span>
                                                            </div>
                                                      </div>
                                                </div>
                                          </div>
                                    </div>
                              </div>
                        </div>
                  </div>
                  <div className='edu-info'>
                        <p>Pagamento sicuro. Offerta valida per il primo semestre, rinnovo automatico allo scadere dei 6 mesi.<br />Disdici quando vuoi</p>
                  </div>
                  <div className='edu-faq'>
                        <div>
                              <h1>Informazioni</h1>
                              <Accordion defaultActiveKey="0">
                                    <Accordion.Item eventKey="0">
                                          <Accordion.Header>Come posso acquistare EDU?</Accordion.Header>
                                          <Accordion.Body>
                                          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                          aliquip ex ea commodo consequat. Duis aute irure dolor in
                                          reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                          pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                          culpa qui officia deserunt mollit anim id est laborum.
                                          </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="1">
                                          <Accordion.Header>Non possiedo una carta di credito o di 
                                          debito.<br />Come posso acquistare EDU?</Accordion.Header>
                                          <Accordion.Body>
                                          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                          aliquip ex ea commodo consequat. Duis aute irure dolor in
                                          reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                          pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                          culpa qui officia deserunt mollit anim id est laborum.
                                          </Accordion.Body>
                                    </Accordion.Item>
                              </Accordion>
                        </div>


                  </div>
            </section>
      );
}
