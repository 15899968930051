import "./profile.css";
import { useState } from 'react';
import {useNavigate,Link} from 'react-router-dom';
import CustomModal from '../../utilities/modals';

const DeleteUser = () => {
	const navigate=useNavigate();
    const [showModal, setShowModal] = useState(false);
    const [modalType, setModalType] = useState('deleteUser');

    const handleCloseModal = () => setShowModal(false);
    const handleShowModal = (type) => {
        setModalType(type);
        setShowModal(true);
    };

    const handleSubmitModal1 = () => {
        handleCloseModal();
        // da gestire il redirect alla home page (quale sarebbe la home page?)
	navigate('/');
    };

    const modalContent = {
        deleteUser: {
            icon: `${process.env.PUBLIC_URL}/danger.svg`,
            title: 'Cancellazione utente',
            bodyContent: 'Ci dispiace, ma speriamo di rivederti presto!',
            bodySubtitle: 'Cancellazione utente confermata',
        },
    };

    const handleCloseButtonClick = () => {
        handleCloseModal();
    };

	const goBack=()=>{
		navigate(-1);
	}

    return (
        <div className="account-container">
            <div className="account-top">
                <div className="row h-100 align-items-center">
                    <div className="col-1 prev-page">
			<Link to='/'>
                        <img src={`${process.env.PUBLIC_URL}/page-back.svg`} alt="Torna indietro" />
			</Link>
                    </div>
                    <div className="col-10 text-center">
                        <h1>Cancellazione utente</h1>
                    </div>
                    <div className="col-1"></div>
                </div>
            </div>
            <div className="account-body">
                <form>
                    <div className="d-flex align-items-center">
                        <img src={`${process.env.PUBLIC_URL}/danger.svg`} alt="Attenzione" />
                        <h2 className="mb-0">Attenzione</h2>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <p>
                                Stai per cancellare definitivamente l'account utente.<br />
                                Questa operazione è irreversibile e comporterà la perdita di tutti i dati associati all'account, inclusi:
                            </p>
                            <ul>
                                <li>lo storico delle transazioni</li>
                                <li>impostazioni e preferenze</li>
                                <li>accesso a funzionalità e servizi</li>
                            </ul>
                            <p>
                                Per procedere con la cancellazione del tuo account, cliccare sul pulsante "Conferma".<br />
                                In caso contrario, cliccare su "Annulla" per interrompere l'operazione.
                            </p>
                            <p>
                                Ti ricordiamo che la cancellazione dell'account comporterà:
                            </p>
                            <ul>
                                <li>la perdita permanente di tutti i dati associati all'account</li>
                                <li>l'impossibilità di accedere nuovamente ai servizi Giappichelli con le stesse credenziali</li>
                                <li>la revoca di qualsiasi autorizzazione o privilegio concesso all'account</li>
                            </ul>
                        </div>
                    </div>
                    <div className="row buttons-container">
                        <div className="col-6">
                            <input className="gradient-button reset-button" type="reset" value="Annulla" onClick={goBack} />
                        </div>
                        <div className="col-6">
                            <input className="gradient-button" type="button" value="Conferma" onClick={() => handleShowModal('deleteUser')} />
                        </div>
                    </div>
                </form>
            </div>

            {/* Modali */}
            <CustomModal
                modalType={modalType}
                showModal={showModal}
                handleClose={handleCloseModal}
                handleSubmit={handleSubmitModal1}
                icon={modalContent[modalType].icon}
                title={modalContent[modalType].title}
                bodyContent={modalContent[modalType].bodyContent}
                bodySubtitle={modalContent[modalType].bodySubtitle}
                closeButtonText="Home Page"
                closeButtonAction={handleCloseButtonClick}
            />

        </div>
    );
}

export default DeleteUser;
