import './customArrowsCarousel.css';
import { useState } from 'react';

const CustomLeftArrow = ({ onClick }) => (
    <i onClick={() => onClick()} className="custom-left-arrow" />
);

const CustomRightArrow = ({ onClick }) => {
    return <i className="custom-right-arrow" onClick={() => onClick()} />;
};

const CustomButtonGroup = ({ next, previous }) => {
    const [leftArrow, setLeftArrow] = useState(`${process.env.PUBLIC_URL}/arrow-left.png`);
    const [rightArrow, setRightArrow] = useState(`${process.env.PUBLIC_URL}/arrow-right.png`);

    return (
        <div className="custom-button-group">
            <button
                onMouseEnter={() => setLeftArrow(`${process.env.PUBLIC_URL}/over-arrow-left.png`)}  // Cambia immagine on hover
                onMouseLeave={() => setLeftArrow(`${process.env.PUBLIC_URL}/arrow-left.png`)}       // Torna all'immagine originale
                onClick={() => previous()}
            >
                <img alt="previous" src={leftArrow} />
            </button>
            <button
                onMouseEnter={() => setRightArrow(`${process.env.PUBLIC_URL}/over-arrow-right.png`)} // Cambia immagine on hover
                onMouseLeave={() => setRightArrow(`${process.env.PUBLIC_URL}/arrow-right.png`)}      // Torna all'immagine originale
                onClick={() => next()}
            >
                <img alt="next" src={rightArrow} />
            </button>
        </div>
    );
};

const CustomButtonGroupAsArrows = ({ next, previous }) => {
    return (
        <div
            style={{
                textAlign: "center",
            }}
        >
            <h4>These buttons can be positioned anywhere you want on the screen</h4>
            <button onClick={previous}>Prev</button>
            <button onClick={next}>Next</button>
        </div>
    );
};

export {
    CustomLeftArrow,
    CustomRightArrow,
    CustomButtonGroup,
    CustomButtonGroupAsArrows,
};
