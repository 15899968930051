import Accordion from 'react-bootstrap/Accordion';
import './helpcenter.css';
import { Link } from 'react-router-dom';
import PrevPage from '../utilities/prevPage';

const HelpCenter = () => {

    return (
        <div className="account-container">
            <div className="account-top">
                <div className="row h-100 align-items-center">
                    <PrevPage />
                    <div className="col-10 text-center">
                        <h1>Help Center</h1>
                    </div>
                    <div className="col-1"></div>
                </div>
            </div>
            <div className="account-body">
                <h2>Domande frequenti</h2>
                <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>Accordion Item #1</Accordion.Header>
                        <Accordion.Body>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                            minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                            aliquip ex ea commodo consequat. Duis aute irure dolor in
                            reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                            pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                            culpa qui officia deserunt mollit anim id est laborum.
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                        <Accordion.Header>Accordion Item #2</Accordion.Header>
                        <Accordion.Body>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                            minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                            aliquip ex ea commodo consequat. Duis aute irure dolor in
                            reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                            pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                            culpa qui officia deserunt mollit anim id est laborum.
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
                <h2 className='mb-0'>Assistenza clienti</h2>
                <div className='row customer-care'>
                    <div className='col-6'>
                        <h3>Titolo</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla congue iaculis urna, vel vehicula augue commodo sed. Phasellus in libero condimentum felis consectetur commodo. Ut id rhoncus nisi. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.</p>
                        <hr />
                        <a href="">Link a qualcosa <img src={`${process.env.PUBLIC_URL}/arrow-link.svg`} alt="leggi tutto" /></a>
                    </div>
                    <div className='col-6'>
                        <h3>Titolo</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla congue iaculis urna, vel vehicula augue commodo sed. Phasellus in libero condimentum felis consectetur commodo. Ut id rhoncus nisi. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.</p>
                        <hr />
                        <a href="">Link a qualcosa  <img src={`${process.env.PUBLIC_URL}/arrow-link.svg`} alt="leggi tutto" /></a>
                    </div>
                </div>
            </div>
        </div>
    );

}

export default HelpCenter;
