export default function CardBook({isnew,imageurl,title,authors,edition,pubdate,url}){
	const pb=new Date(pubdate);
	const sPubDate=(pb.getMonth()<9?'0':'')+(pb.getMonth()+1)+'/'+pb.getFullYear();
	return (<div>
		<div className='prod-cover'>
			<a href={url}>
				{isnew ? (<button className='chip'><span>Novità</span></button>):(<></>)}
				<img src={imageurl?imageurl:`${process.env.PUBLIC_URL}/cover1.jpg`} alt={{title}} />
				{/* <button className='chip lock'></button> */}
				<h6>{title}</h6>
				<p>{authors}</p>
				<p>{edition} Edizione del <span>{sPubDate}</span></p>
			</a>
		</div>
	</div>);
};
