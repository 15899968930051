import {useContext,useEffect} from 'react';
import { useParams,useNavigate/*,useLocation*/ } from 'react-router-dom';
import { useAuth } from 'react-oidc-context';
import AppContext from '../../AppContext';
import {uscall/*,getfirst*/} from '../../us';

const CreateCheckout=function(){
	const {productcode}=useParams();
	const navigate=useNavigate();
	const auth = useAuth();
	const appcontext = useContext(AppContext);

	useEffect(() => {
		if(auth.isAuthenticated);
		else 
			navigate('/login/?redirect='+encodeURIComponent(document.location.href));
	},[auth,navigate]);

	useEffect(()=>{
		const cb=async function(){
			const r=(await uscall(appcontext,auth,'order','order','order_create_with_product_code',encodeURIComponent(productcode)));
			if(r && typeof(r['error'])!='undefined'){
				alert(r.error.description);
				navigate(-1);
				return;
			}
			navigate('/checkout/'+encodeURIComponent(r.result)+'/');
		};
		if(auth && auth.isAuthenticated);
			cb();
	},[auth,navigate,appcontext,productcode]);
};
export default CreateCheckout;
