import './landing.css';

function CollaborateLanding() {
   
    return (
        <section className='content-landing'>
            <div className="collaborate-landing d-flex">
                <h1>Collabora con noi</h1>
                <div className='collaborate-cards'>
                    <div className='card-info'>
                        <div className='card-content'>
                            <h5>Pubblica con noi</h5>
                            <p>Sei interessato a pubblicare con noi? Compila il form e verrai ricontattato.</p>
                            <hr />
                            <a>Vai al form</a>
                        </div>
                    </div>
                    <div className='card-info'>
                        <div className='card-content'>
                            <h5>Chiedi una copia saggio per adozione</h5>
                            <p>Se sei interessato ad adottare un nostro manuale o vuoi conoscere le risorse digitali per la didattica? Compila il form e verrai ricontattato.</p>
                            <hr />
                            <a>Vai al form</a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}


export default CollaborateLanding;