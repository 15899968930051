import Form from 'react-bootstrap/Form';

const CheckoutBilling=function({orderinfo,SwapCheckbox,setOrderInfo,updateOrderData}){
	const setIsCompany=function(){
		let neworderinfo={...orderinfo};
		neworderinfo.billing_iscompany=true;
		setOrderInfo(neworderinfo);
	};
	const setIsCustomer=function(){
		let neworderinfo={...orderinfo};
		neworderinfo.billing_iscompany=false;
		setOrderInfo(neworderinfo);
	};
	const useSameAddressAsShipment=function(event){
		let neworderinfo={...orderinfo};
		if(event.target.checked){
			neworderinfo.billingaddress_firstname=neworderinfo.shipment_firstname;
			neworderinfo.billingaddress_lastname=neworderinfo.shipment_lastname;
			neworderinfo.billingaddress_address=neworderinfo.shipment_address;
			neworderinfo.billingaddress_at=neworderinfo.shipment_at;
			neworderinfo.billingaddress_mobile=neworderinfo.shipment_mobile;
			neworderinfo.billingaddress_asshipment=true;
		}else
			neworderinfo.billingaddress_asshipment=false;
		setOrderInfo(neworderinfo);
	};

	return (<>
                    <div className='form-checkout'>
                        <div className='row'>
                            <div className='col'>
                                <div>
                                <Form.Check
                                    type="switch"
                                    label="Richiedi fattura"
				    name='hasbilling'
				    checked={orderinfo.hasbilling}
				    onClick={SwapCheckbox}
                                />
                                </div>

			<>{orderinfo.hasbilling?(<>
                                <div>
                                    <h4>Dati fatturazione</h4>
                                </div>
                        <div className='row g-0'>
                            <div className={`col customer-type ${orderinfo && orderinfo.billing_iscompany?' active':''}`} onClick={setIsCompany}>
                            <span>Azienda</span>
                            </div>
                            <div className={`col customer-type ${orderinfo && orderinfo.billing_iscompany?'':' active'}`} onClick={setIsCustomer}>
                            <span>Privato</span>                         
                            </div>
                        </div>

			<>{orderinfo && orderinfo.billing_iscompany?(<>
                        <div className='row'>
                            <div className='col-6'>
                                <label htmlFor="billing_name">Ragione sociale *</label>
                                <input name='billing_name' type='text' value={orderinfo.billing_name} onChange={updateOrderData} />
                            </div>
                            <div className='col-6'>
                                <label htmlFor="billing_vat">Partita IVA *</label>
                                <input name='billing_vat' type='text' value={orderinfo.billing_vat} onChange={updateOrderData} />
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-6'>
                                <label htmlFor="billing_sdi">Codice univoco SDI *</label>
                                <input name='billing_sdi' type='text'  value={orderinfo.billing_sdi} onChange={updateOrderData}/>
                            </div>
                            <div className='col-6'>
                                <label htmlFor="billing_pec">Posta certificata PEC</label>
                                <input name='billing_pec' type='text'  value={orderinfo.billing_pec} onChange={updateOrderData}/>
                            </div>
                        </div>
			</>):(<>
                        <div className='row'>
                            <div className='col-6'>
                                <label htmlFor="billing_ssn">Codice Fiscale *</label>
                                <input name='billing_ssn' type='text'  value={orderinfo.billing_ssn} onChange={updateOrderData}/>
                            </div>
                            <div className='col-6'>
                                <label htmlFor="billing_pec">Posta certificata PEC</label>
                                <input name='billing_pec' type='text'  value={orderinfo.billing_pec} onChange={updateOrderData}/>
                            </div>
                        </div>
			</>)}</>
                        <div className='divider-row'></div>
                        <div className='row pt-3'>
                            <div className='col'><h4>Indirizzo di fatturazione</h4></div>
			<>{orderinfo && orderinfo.hasshipment?(
                            <div className='col-auto'>
                                <div className='save-address'>
                                    <input type='checkbox' checked={orderinfo.billingaddress_asshipment} onClick={useSameAddressAsShipment} />
                                    <p>Usa lo stesso indirizzo della spedizione</p>
                                </div>
                            </div>):(<></>)}</>
                        </div>
			<>{orderinfo && orderinfo.billingaddress_asshipment?(<></>):(<>
                        <div className='row pt-4'>
                            <div className='col-6'>
                                <label htmlFor="billingaddress_firstname">Nome *</label>
                                <input name='billingaddress_firstname' type='text' value={orderinfo.billingaddress_firstname}  onChange={updateOrderData} />
                            </div>
                            <div className='col-6'>
                                <label htmlFor="billingaddress_lastname">Cognome *</label>
                                <input name='billingaddress_lastname' type='text' value={orderinfo.billingaddress_lastname}  onChange={updateOrderData} />
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col'>
                                <label htmlFor="billingaddress_address">Indirizzo *</label>
                                <input name='billingaddress_address' type='text' value={orderinfo.billingaddress_address}  onChange={updateOrderData} />
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-6'>
                                <label htmlFor="billingaddress_at">Presso</label>
                                <input name='billingaddress_at' type='text' value={orderinfo.billingaddress_at}  onChange={updateOrderData} />
                            </div>
                            <div className='col-6'>
                                <label htmlFor="billingaddress_mobile">Cellulare</label>
                                <input name='billingaddress_mobile' type='number' value={orderinfo.billingaddress_mobile}  onChange={updateOrderData} />
                            </div>
                        </div>
                        <div className='row pt-3'>
                            <div className='col'></div>
                            <div className='col-auto'>
                                <div className='save-address'>
                                    <input type='checkbox' name='billing_save' checked={orderinfo.billing_save} onClick={SwapCheckbox} />
                                    <p>Salva indirizzo di fatturazione</p>
                                </div>
                            </div>
                        </div>
			</>)}</>

			</>):(<></>)}</>
                            </div>
                        </div>
		</div>

</>);
};
export default CheckoutBilling;
